import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import { WithTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { GuestType, Product } from '../../models/product';

// getGuestTypesUsedInProduct returns the guest types from product data.
// ** Adapted from nutmeg **
export const getGuestTypesUsedInProduct = (
  product: Product,
  t: WithTranslation['t']
): GuestType[] => {
  const activeAndFuturePriceSchedules = (product.pricing ?? []).filter(
    (priceSchedule) =>
      !priceSchedule.end_date_local ||
      priceSchedule.end_date_local >=
        moment()
          .tz(product.start_timezone ?? '')
          .format('YYYY-MM-DD')
  );

  if (activeAndFuturePriceSchedules.length === 0) {
    return [];
  }

  {
    const guestTypes = flatten(
      activeAndFuturePriceSchedules.map((sched) =>
        sched.units.filter((unit) => unit.method === 'PER_PARTICIPANT').map((u) => u.guest_type)
      )
    );
    if (guestTypes.length > 0) {
      return uniqBy(guestTypes, (g) => g.key);
    }
  }

  {
    const perBookingGuestTypes = flatten(
      activeAndFuturePriceSchedules.map(
        (sched) =>
          sched.units.find((unit) => unit.method === 'PER_BOOKING' && unit.per_booking_guest_types)
            ?.per_booking_guest_types ?? []
      )
    );

    if ((perBookingGuestTypes ?? []).length > 0) {
      return uniqBy(perBookingGuestTypes, (g) => g.key);
    }
  }

  if (product.add_ons) {
    for (const addOn of product.add_ons) {
      if (addOn.pricing) {
        const guestTypes = addOn.pricing
          .filter((u) => u.method === 'PER_PARTICIPANT')
          .map((u) => u.guest_type);
        if (guestTypes.length > 0) {
          return guestTypes;
        }
      }
    }
  }

  if (product.transportations) {
    for (const transportation of product.transportations) {
      if (transportation.pricing) {
        const guestTypes = transportation.pricing
          .filter((u) => u.method === 'PER_PARTICIPANT')
          .map((u) => u.guest_type);
        if (guestTypes.length > 0) {
          return guestTypes;
        }
      }
    }
  }

  return [
    {
      key: 'guest',
      title: t('Guest'),
    },
  ];
};
