import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { EquipmentInstance } from 'models/equipmentInstance';
import { createAction } from 'ducks/actionHelpers';

// Actions

const FETCH_EQUIPMENT_INSTANCES_REQUEST = 'FETCH_EQUIPMENT_INSTANCES_REQUEST';
const FETCH_EQUIPMENT_INSTANCES_SUCCESS = 'FETCH_EQUIPMENT_INSTANCES_SUCCESS';
const FETCH_EQUIPMENT_INSTANCES_FAILURE = 'FETCH_EQUIPMENT_INSTANCES_FAILURE';

// Action creators
const fetchEquipmentInstancesRequest = () => createAction(FETCH_EQUIPMENT_INSTANCES_REQUEST);
const fetchEquipmentInstancesSuccess = (response: EquipmentInstance) =>
  createAction(FETCH_EQUIPMENT_INSTANCES_SUCCESS, response);
const fetchEquipmentInstancesFailure = (err: string) =>
  createAction(FETCH_EQUIPMENT_INSTANCES_FAILURE, err);

export const fetchEquipmentInstances = (
  apiKey: string,
  productInstanceId: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchEquipmentInstancesRequest());
  return axios
    .get(`${config.apiUrl}/equipments/-/instances`, {
      params: {
        product_instance_id: productInstanceId,
        include_combined_equipment_block_instance_properties: true,
        include_occupied_equipment_block_instance_keys: true,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(fetchEquipmentInstancesSuccess(response.data.equipment_instances));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(fetchEquipmentInstancesFailure('canceled'));
      } else {
        dispatch(fetchEquipmentInstancesFailure(err.message));
      }
    });
};

type Action =
  | ReturnType<typeof fetchEquipmentInstancesRequest>
  | ReturnType<typeof fetchEquipmentInstancesSuccess>
  | ReturnType<typeof fetchEquipmentInstancesFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_EQUIPMENT_INSTANCES_FAILURE:
      return action.payload;
    case FETCH_EQUIPMENT_INSTANCES_REQUEST:
    case FETCH_EQUIPMENT_INSTANCES_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: EquipmentInstance[] = [], action: Action) => {
  switch (action.type) {
    case FETCH_EQUIPMENT_INSTANCES_SUCCESS:
      return action.payload as EquipmentInstance[];
    default:
      return state;
  }
};

export interface EquipmentInstancesState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
}

export default combineReducers({
  error,
  all,
});
