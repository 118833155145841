import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { REDEEM_STAMP_RALLY_SUCCESS, redeemStampRallySuccess } from 'ducks/client/stampRallies';
import {
  REDEEM_STAMP_RALLY_GIFT_SUCCESS,
  redeemStampRallyGiftSuccess,
} from 'ducks/client/stampRallyGifts';

import config from 'config';
import {
  GuidanceStampRally,
  GuidanceStampRallyRedemption,
  GuidanceStampRallyGiftRedemption,
} from 'models/guidanceStampRally';
import { createAction } from 'ducks/actionHelpers';

const FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST =
  'FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST';
const FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS =
  'FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS';
const FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE =
  'FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE';

const fetchDigitalGuidanceStampRallyRequest = () =>
  createAction(FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST);
const fetchDigitalGuidanceStampRallySuccess = (response: GuidanceStampRally[]) =>
  createAction(FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS, response);
const fetchDigitalGuidanceStampRallyFailure = (err: string) =>
  createAction(FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE, err);

export const fetchReservationDigitalGuidanceStampRallies = (
  apiKey: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchDigitalGuidanceStampRallyRequest());

  const path = `${config.apiUrl}/reservations/${reservationId}/guidancestamprallies`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchDigitalGuidanceStampRallySuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchDigitalGuidanceStampRallyFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchDigitalGuidanceStampRallyRequest>
  | ReturnType<typeof fetchDigitalGuidanceStampRallySuccess>
  | ReturnType<typeof fetchDigitalGuidanceStampRallyFailure>
  | ReturnType<typeof redeemStampRallySuccess>
  | ReturnType<typeof redeemStampRallyGiftSuccess>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE:
      return action.payload;
    case FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_REQUEST:
    case FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS:
      return '';
    default:
      return state;
  }
};

const stampRallies = (state: GuidanceStampRally[] | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_SUCCESS:
      return action.payload?.map((page) => page);
    case FETCH_RESERVATION_DIGITAL_GUIDANCE_STAMP_RALLY_FAILURE:
      return null;
    case REDEEM_STAMP_RALLY_SUCCESS: {
      const stampRally = (state || []).find(
        (stampRally) => stampRally.id === action.payload.stamp_rally_id
      );
      if (stampRally) {
        const stampRallyRedemptions = stampRally?.stamp_rally_redemptions || [];
        stampRallyRedemptions.push(action.payload as GuidanceStampRallyRedemption);
        stampRally.stamp_rally_redemptions = stampRallyRedemptions;
      }
      return [...(state ?? [])];
    }
    case REDEEM_STAMP_RALLY_GIFT_SUCCESS: {
      const stampRally = (state || []).find(
        (stampRally) => stampRally.id === action.payload.stamp_rally_id
      );
      if (stampRally) {
        const stampRallyGiftRedemptions = stampRally?.stamp_rally_gift_redemptions || [];
        stampRallyGiftRedemptions.push(action.payload as GuidanceStampRallyGiftRedemption);
        stampRally.stamp_rally_gift_redemptions = stampRallyGiftRedemptions;
      }
      return [...(state ?? [])];
    }
    default:
      return state;
  }
};

export interface ReservationDigitalGuidanceStampRalliesState {
  error: ReturnType<typeof error>;
  stampRallies: ReturnType<typeof stampRallies>;
}

export default combineReducers({
  error,
  stampRallies,
});
