import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TopPage } from '@nutmeglabs/falcon-ui';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';

// Actions
const CUSTOM_TOP_PAGE_REQUEST = 'CUSTOM_TOP_PAGE_REQUEST';
export const CUSTOM_TOP_PAGE_SUCCESS = 'CUSTOM_TOP_PAGE_SUCCESS';
const CUSTOM_TOP_PAGE_FAILURE = 'CUSTOM_TOP_PAGE_FAILURE';

// Action creators
const customTopPageRequest = () => createAction(CUSTOM_TOP_PAGE_REQUEST);
const customTopPageSuccess = (payload: TopPage) => createAction(CUSTOM_TOP_PAGE_SUCCESS, payload);
const customTopPageFailure = (payload: string) => createAction(CUSTOM_TOP_PAGE_FAILURE, payload);

export const fetchCustomTopPage = (apiKey: string, contentLanguage: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(customTopPageRequest());
  return axios
    .get(
      `${config.apiUrl}/customtoppage?language=${contentLanguage.toUpperCase().replace('-', '_')}`,
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      const data = JSON.parse(response.data?.data);
      dispatch(customTopPageSuccess(data));
    })
    .catch((err) => {
      dispatch(customTopPageFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof customTopPageRequest>
  | ReturnType<typeof customTopPageSuccess>
  | ReturnType<typeof customTopPageFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case CUSTOM_TOP_PAGE_FAILURE:
      return action.payload;
    case CUSTOM_TOP_PAGE_REQUEST:
    case CUSTOM_TOP_PAGE_SUCCESS:
      return '';
    default:
      return state;
  }
};

const page = (state: TopPage | null = null, action: Action) => {
  switch (action.type) {
    case CUSTOM_TOP_PAGE_SUCCESS:
      return action.payload;
    case CUSTOM_TOP_PAGE_REQUEST:
    case CUSTOM_TOP_PAGE_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface CustomTopPageState {
  error: ReturnType<typeof error>;
  page: ReturnType<typeof page>;
}

export default combineReducers({
  error,
  page,
});
