import { CustomerTokenType } from 'contexts/CustomerTokenContext';
import { customerIdKey } from 'ducks/client/customers';

export const storeCognitoOAuth = (values: CustomerTokenType) => {
  localStorage.setItem('idToken', values.idToken ?? '');
  localStorage.setItem('accessToken', values.accessToken ?? '');
  localStorage.setItem('refreshToken', values.refreshToken ?? '');
  localStorage.setItem('expiresAt', values?.expiresAt?.toString() ?? '');
  localStorage.setItem('idProvider', values?.idProvider ?? '');
  localStorage.setItem('apiKey', values?.apiKey ?? '');
};

export const clearCognitoOAuth = () => {
  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('expiresAt');
  localStorage.removeItem('idProvider');
  localStorage.removeItem('apiKey');
  localStorage.removeItem(customerIdKey);
};

export const loadCognitoOAuth = (setters: (props: CustomerTokenType) => void) => {
  const idToken = localStorage.getItem('idToken') ?? undefined;
  const accessToken = localStorage.getItem('accessToken') ?? undefined;
  const refreshToken = localStorage.getItem('refreshToken') ?? undefined;
  const expiresAt = localStorage.getItem('expiresAt') ?? undefined;
  const idProvider = localStorage.getItem('idProvider') ?? undefined;
  const apiKey = localStorage.getItem('apiKey') ?? undefined;

  setters({
    idToken,
    accessToken,
    refreshToken,
    expiresAt: expiresAt ? parseInt(expiresAt, 10) : undefined,
    idProvider: idProvider ? (idProvider as any) : undefined,
    apiKey,
  });
};

export const printCognitoOAuth = () => {
  const idToken = localStorage.getItem('idToken') ?? undefined;
  const accessToken = localStorage.getItem('accessToken') ?? undefined;
  const refreshToken = localStorage.getItem('refreshToken') ?? undefined;
  const expiresAt = localStorage.getItem('expiresAt') ?? undefined;
  const idProvider = localStorage.getItem('idProvider') ?? undefined;
  const apiKey = localStorage.getItem('apiKey') ?? undefined;

  console.log('idToken', idToken);
  console.log('accessToken', accessToken);
  console.log('refreshToken', refreshToken);
  console.log('expiresAt', expiresAt);
  console.log('idProvider', idProvider);
  console.log('apiKey', apiKey);
};
