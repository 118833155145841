import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { CreateReviewRequest, Review } from 'models/review';
import { createAction } from 'ducks/actionHelpers';

// Actions

const CREATE_REVIEW_REQUEST = 'CREATE_REVIEW_REQUEST';
const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
const CREATE_REVIEW_FAILURE = 'CREATE_REVIEW_FAILURE';

// Action creators

const createReviewRequest = () => createAction(CREATE_REVIEW_REQUEST);
const createReviewSuccess = (payload: Review) => createAction(CREATE_REVIEW_SUCCESS, payload);
const createReviewFailure = (payload: string) => createAction(CREATE_REVIEW_FAILURE, payload);

export const createReview = (apiKey: string, req: CreateReviewRequest) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(createReviewRequest());
  return axios
    .post(`${config.apiUrl}/reviews`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(createReviewSuccess(response.data));
    })
    .catch((err) => {
      dispatch(createReviewFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof createReviewRequest>
  | ReturnType<typeof createReviewSuccess>
  | ReturnType<typeof createReviewFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case CREATE_REVIEW_FAILURE:
      return action.payload;
    case CREATE_REVIEW_REQUEST:
    case CREATE_REVIEW_SUCCESS:
      return '';
    default:
      return state;
  }
};

const inFlight = (state = false, action: Action) => {
  switch (action.type) {
    case CREATE_REVIEW_REQUEST:
      return true;
    case CREATE_REVIEW_SUCCESS:
    case CREATE_REVIEW_FAILURE:
      return false;
    default:
      return state;
  }
};

const lastCreatedReview = (state: Review | null = null, action: Action) => {
  switch (action.type) {
    case CREATE_REVIEW_SUCCESS:
      return action.payload;
    case CREATE_REVIEW_REQUEST:
    case CREATE_REVIEW_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface ReviewsState {
  error: ReturnType<typeof error>;
  inFlight: ReturnType<typeof inFlight>;
  lastCreatedReview: ReturnType<typeof lastCreatedReview>;
}

export default combineReducers({
  error,
  inFlight,
  lastCreatedReview,
});
