import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import { InstantWinEvent, InstantWinEventParams } from 'models/instantWinEvent';

// Actions
const UPDATE_INSTANT_WIN_EVENT_REQUEST = 'UPDATE_INSTANT_WIN_EVENT_REQUEST';
const UPDATE_INSTANT_WIN_EVENT_SUCCESS = 'UPDATE_INSTANT_WIN_EVENT_SUCCESS';
const UPDATE_INSTANT_WIN_EVENT_FAILURE = 'UPDATE_INSTANT_WIN_EVENT_FAILURE';

const updateInstantWinEventRequest = () => createAction(UPDATE_INSTANT_WIN_EVENT_REQUEST);
const updateInstantWinEventSuccess = (payload: InstantWinEvent) =>
  createAction(UPDATE_INSTANT_WIN_EVENT_SUCCESS, payload);
const updateInstantWinEventFailure = (payload: string) =>
  createAction(UPDATE_INSTANT_WIN_EVENT_FAILURE, payload);

export const updateInstantWinEvent = (
  apiKey: string,
  id: string,
  req: InstantWinEventParams,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(updateInstantWinEventRequest());
  return axios
    .patch(`${config.apiUrl}/instantwinevents/${id}`, req, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(updateInstantWinEventSuccess(response.data));
    })
    .catch((err) => {
      dispatch(updateInstantWinEventFailure(err.message));
    });
};

const FETCH_INSTANT_WIN_EVENTS_REQUEST = 'FETCH_INSTANT_WIN_EVENTS_REQUEST';
const FETCH_INSTANT_WIN_EVENTS_SUCCESS = 'FETCH_INSTANT_WIN_EVENTS_SUCCESS';
const FETCH_INSTANT_WIN_EVENTS_FAILURE = 'FETCH_INSTANT_WIN_EVENTS_FAILURE';

const fetchInstantWinEventsRequest = () => createAction(FETCH_INSTANT_WIN_EVENTS_REQUEST);
const fetchInstantWinEventsSuccess = (payload: InstantWinEvent[]) =>
  createAction(FETCH_INSTANT_WIN_EVENTS_SUCCESS, payload);
const fetchInstantWinEventsFailure = (payload: string) =>
  createAction(FETCH_INSTANT_WIN_EVENTS_FAILURE, payload);

export const fetchInstantWinEvents = (
  apiKey: string,
  entityId: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchInstantWinEventsRequest());
  return axios
    .get(`${config.apiUrl}/instantwinevents?entity_id=${entityId}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      params: { entityId },
    })
    .then((response) => {
      dispatch(fetchInstantWinEventsSuccess(response.data.instant_win_events));
    })
    .catch((err) => {
      dispatch(fetchInstantWinEventsFailure(err.message));
    });
};

const EXCHANGE_INSTANT_WIN_EVENT_GIFT_REQUEST = 'EXCHANGE_INSTANT_WIN_EVENT_GIFT_REQUEST';
const EXCHANGE_INSTANT_WIN_EVENT_GIFT_SUCCESS = 'EXCHANGE_INSTANT_WIN_EVENT_GIFT_SUCCESS';
const EXCHANGE_INSTANT_WIN_EVENT_GIFT_FAILURE = 'EXCHANGE_INSTANT_WIN_EVENT_GIFT_FAILURE';

const exchangeInstantWinEventGiftRequest = () =>
  createAction(EXCHANGE_INSTANT_WIN_EVENT_GIFT_REQUEST);
const exchangeInstantWinEventGiftSuccess = (payload: InstantWinEvent) =>
  createAction(EXCHANGE_INSTANT_WIN_EVENT_GIFT_SUCCESS, payload);
const exchangeInstantWinEventGiftFailure = (payload: string) =>
  createAction(EXCHANGE_INSTANT_WIN_EVENT_GIFT_FAILURE, payload);

export const exchangeInstantWinEventGift = (
  apiKey: string,
  id: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(exchangeInstantWinEventGiftRequest());
  return axios
    .post(`${config.apiUrl}/instantwinevents/${id}/exchangegift`, null, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(exchangeInstantWinEventGiftSuccess(response.data));
    })
    .catch((err) => {
      dispatch(exchangeInstantWinEventGiftFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof updateInstantWinEventRequest>
  | ReturnType<typeof updateInstantWinEventSuccess>
  | ReturnType<typeof updateInstantWinEventFailure>
  | ReturnType<typeof fetchInstantWinEventsRequest>
  | ReturnType<typeof fetchInstantWinEventsSuccess>
  | ReturnType<typeof fetchInstantWinEventsFailure>
  | ReturnType<typeof exchangeInstantWinEventGiftRequest>
  | ReturnType<typeof exchangeInstantWinEventGiftSuccess>
  | ReturnType<typeof exchangeInstantWinEventGiftFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case UPDATE_INSTANT_WIN_EVENT_FAILURE:
    case FETCH_INSTANT_WIN_EVENTS_FAILURE:
    case EXCHANGE_INSTANT_WIN_EVENT_GIFT_FAILURE:
      return action.payload;
    case UPDATE_INSTANT_WIN_EVENT_REQUEST:
    case UPDATE_INSTANT_WIN_EVENT_SUCCESS:
    case FETCH_INSTANT_WIN_EVENTS_REQUEST:
    case FETCH_INSTANT_WIN_EVENTS_SUCCESS:
    case EXCHANGE_INSTANT_WIN_EVENT_GIFT_REQUEST:
    case EXCHANGE_INSTANT_WIN_EVENT_GIFT_SUCCESS:
      return '';
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case UPDATE_INSTANT_WIN_EVENT_REQUEST:
    case FETCH_INSTANT_WIN_EVENTS_REQUEST:
    case EXCHANGE_INSTANT_WIN_EVENT_GIFT_REQUEST:
      return true;
    case UPDATE_INSTANT_WIN_EVENT_SUCCESS:
    case UPDATE_INSTANT_WIN_EVENT_FAILURE:
    case FETCH_INSTANT_WIN_EVENTS_SUCCESS:
    case FETCH_INSTANT_WIN_EVENTS_FAILURE:
    case EXCHANGE_INSTANT_WIN_EVENT_GIFT_SUCCESS:
    case EXCHANGE_INSTANT_WIN_EVENT_GIFT_FAILURE:
      return false;
    default:
      return state;
  }
};

const instantWinEvent = (state: InstantWinEvent | null = null, action: Action) => {
  switch (action.type) {
    case UPDATE_INSTANT_WIN_EVENT_SUCCESS:
    case EXCHANGE_INSTANT_WIN_EVENT_GIFT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const all = (state: InstantWinEvent[] = [], action: Action) => {
  switch (action.type) {
    case FETCH_INSTANT_WIN_EVENTS_SUCCESS:
      return action.payload;
    case UPDATE_INSTANT_WIN_EVENT_SUCCESS:
    case EXCHANGE_INSTANT_WIN_EVENT_GIFT_SUCCESS:
      return state.map((event) => (event.id === action.payload.id ? action.payload : event));
    default:
      return state;
  }
};

export interface InstantWinEventState {
  error: ReturnType<typeof error>;
  loading: ReturnType<typeof loading>;
  instantWinEvent: ReturnType<typeof instantWinEvent>;
  all: ReturnType<typeof all>;
}

export default combineReducers({
  error,
  loading,
  instantWinEvent,
  all,
});
