import { WebPushNotification } from 'models/webPushNotification';
import { createAction } from 'ducks/actionHelpers';
import { combineReducers } from 'redux';

const ADD_WEB_PUSH_NOTIFICATION = 'ADD_WEB_PUSH_NOTIFICATION';
const REMOVE_WEB_PUSH_NOTIFICATION = 'REMOVE_WEB_PUSH_NOTIFICATION';

let idCounter = 1;
export const addWebPushNotification = (notification: WebPushNotification) =>
  createAction(ADD_WEB_PUSH_NOTIFICATION, notification);
export const removeWebPushNotification = (id: string) =>
  createAction(REMOVE_WEB_PUSH_NOTIFICATION, id);

export const ENABLE_WEB_PUSH_NOTIFICATION = 'ENABLE_WEB_PUSH_NOTIFICATION';
export const enableWebPushNotification = (trueOrFalse: boolean) => {
  return createAction(ENABLE_WEB_PUSH_NOTIFICATION, trueOrFalse);
};

type Action =
  | ReturnType<typeof addWebPushNotification>
  | ReturnType<typeof removeWebPushNotification>
  | ReturnType<typeof enableWebPushNotification>;

export const marketingAutomationWebPushNotifications = (
  state: WebPushNotification[] = [],
  action: Action
): WebPushNotification[] => {
  switch (action.type) {
    case ADD_WEB_PUSH_NOTIFICATION: {
      const newState = [
        ...state,
        {
          ...action.payload,
          id: `${idCounter}`,
        },
      ];
      idCounter++;
      return newState;
    }

    case REMOVE_WEB_PUSH_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.payload);

    default:
      return state;
  }
};

export const webPushNotificationEnabled = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case ENABLE_WEB_PUSH_NOTIFICATION:
      return action.payload;
    default:
      return state;
  }
};

export interface WebPushNotificationState {
  marketingAutomationWebPushNotifications: WebPushNotification[];
  webPushNotificationEnabled: boolean;
}

export default combineReducers({
  marketingAutomationWebPushNotifications,
  webPushNotificationEnabled,
});
