import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { GetGoogleWalletPassJWTResponse } from 'models/walletPass';
import { createAction } from 'ducks/actionHelpers';

// Actions

const FETCH_GOOGLE_WALLET_PASS_JWT_REQUEST = 'FETCH_GOOGLE_WALLET_PASS_JWT_REQUEST';
const FETCH_GOOGLE_WALLET_PASS_JWT_SUCCESS = 'FETCH_GOOGLE_WALLET_PASS_JWT_SUCCESS';
const FETCH_GOOGLE_WALLET_PASS_JWT_FAILURE = 'FETCH_GOOGLE_WALLET_PASS_JWT_FAILURE';

// Action creators

const fetchGoogleWalletPassJWTRequest = () => createAction(FETCH_GOOGLE_WALLET_PASS_JWT_REQUEST);
const fetchGoogleWalletPassJWTSuccess = (payload: string) =>
  createAction(FETCH_GOOGLE_WALLET_PASS_JWT_SUCCESS, payload);
const fetchGoogleWalletPassJWTFailure = (payload: string) =>
  createAction(FETCH_GOOGLE_WALLET_PASS_JWT_FAILURE, payload);

export const fetchGoogleWalletPassJWT = (apiKey: string, id: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchGoogleWalletPassJWTRequest());
  return axios
    .get(`${config.apiUrl}/googlewalletpassjwt/${id}`, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(
        fetchGoogleWalletPassJWTSuccess((response.data as GetGoogleWalletPassJWTResponse).token)
      );
    })
    .catch((err) => {
      dispatch(fetchGoogleWalletPassJWTFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchGoogleWalletPassJWTRequest>
  | ReturnType<typeof fetchGoogleWalletPassJWTSuccess>
  | ReturnType<typeof fetchGoogleWalletPassJWTFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_GOOGLE_WALLET_PASS_JWT_FAILURE:
      return action.payload;
    case FETCH_GOOGLE_WALLET_PASS_JWT_REQUEST:
    case FETCH_GOOGLE_WALLET_PASS_JWT_SUCCESS:
      return '';
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case FETCH_GOOGLE_WALLET_PASS_JWT_REQUEST:
      return true;
    case FETCH_GOOGLE_WALLET_PASS_JWT_SUCCESS:
    case FETCH_GOOGLE_WALLET_PASS_JWT_FAILURE:
      return false;
    default:
      return state;
  }
};

const googleWalletPassJWT = (state: string = '', action: Action) => {
  switch (action.type) {
    case FETCH_GOOGLE_WALLET_PASS_JWT_SUCCESS:
      return action.payload;
    case FETCH_GOOGLE_WALLET_PASS_JWT_REQUEST:
    case FETCH_GOOGLE_WALLET_PASS_JWT_FAILURE:
      return '';
    default:
      return state;
  }
};

export interface WalletPassState {
  error: ReturnType<typeof error>;
  loading: ReturnType<typeof loading>;
  googleWalletPassJWT: ReturnType<typeof googleWalletPassJWT>;
}

export default combineReducers({
  error,
  loading,
  googleWalletPassJWT,
});
