import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { WaiverTemplate } from 'models/waiverTemplate';
import { createAction } from 'ducks/actionHelpers';

// Actions
const WAIVER_TEMPLATE_REQUEST = 'WAIVER_TEMPLATE_REQUEST';
const WAIVER_TEMPLATE_SUCCESS = 'WAIVER_TEMPLATE_SUCCESS';
const WAIVER_TEMPLATE_FAILURE = 'WAIVER_TEMPLATE_FAILURE';

// Action creators
const fetchWaiverTemplateRequest = () => createAction(WAIVER_TEMPLATE_REQUEST);
const fetchWaiverTemplateSuccess = (response: WaiverTemplate) =>
  createAction(WAIVER_TEMPLATE_SUCCESS, response);
const fetchWaiverTemplateFailure = (err: string) => createAction(WAIVER_TEMPLATE_FAILURE, err);

export const fetchWaiverTemplate = (
  apiKey: string,
  waiverTemplateId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchWaiverTemplateRequest());

  const headers: Record<string, string> = {
    'x-api-key': apiKey,
    'accept-language': contentLanguage,
  };

  return axios
    .get(`${config.apiUrl}/waivertemplates/${waiverTemplateId}`, {
      headers,
    })
    .then((response) => {
      dispatch(fetchWaiverTemplateSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchWaiverTemplateFailure(err.message));
    });
};

// Action creators
const fetchWaiverTemplateForReservationRequest = () => createAction(WAIVER_TEMPLATE_REQUEST);
const fetchWaiverTemplateForReservationSuccess = (response: WaiverTemplate) =>
  createAction(WAIVER_TEMPLATE_SUCCESS, response);
const fetchWaiverTemplateForReservationFailure = (err: string) =>
  createAction(WAIVER_TEMPLATE_FAILURE, err);

export const fetchWaiverTemplateForReservation = (
  apiKey: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchWaiverTemplateForReservationRequest());

  const headers: Record<string, string> = {
    'x-api-key': apiKey,
    'accept-language': contentLanguage,
  };

  return axios
    .get(`${config.apiUrl}/reservations/${reservationId}/waivertemplate`, {
      headers,
    })
    .then((response) => {
      dispatch(fetchWaiverTemplateForReservationSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchWaiverTemplateForReservationFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchWaiverTemplateForReservationRequest>
  | ReturnType<typeof fetchWaiverTemplateForReservationSuccess>
  | ReturnType<typeof fetchWaiverTemplateForReservationFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case WAIVER_TEMPLATE_FAILURE:
      return action.payload;
    case WAIVER_TEMPLATE_REQUEST:
    case WAIVER_TEMPLATE_SUCCESS:
      return '';
    default:
      return state;
  }
};

const waiverTemplate = (state: WaiverTemplate | null = null, action: Action) => {
  switch (action.type) {
    case WAIVER_TEMPLATE_SUCCESS:
      return action.payload;
    case WAIVER_TEMPLATE_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface WaiverTemplatesState {
  error: ReturnType<typeof error>;
  waiverTemplate: ReturnType<typeof waiverTemplate>;
}

export default combineReducers({
  error,
  waiverTemplate,
});
