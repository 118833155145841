import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { Waiver } from 'models/waiver';
import { createAction } from 'ducks/actionHelpers';

const WAIVER_REQUEST = 'WAIVER_REQUEST';
const WAIVER_SUCCESS = 'WAIVER_SUCCESS';
const WAIVER_FAILURE = 'WAIVER_FAILURE';

const fetchWaiverRequest = () => createAction(WAIVER_REQUEST);
const fetchWaiverSuccess = (response: Waiver) => createAction(WAIVER_SUCCESS, response);
const fetchWaiverFailure = (err: string) => createAction(WAIVER_FAILURE, err);

export const fetchWaiver = (apiKey: string, waiverId: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchWaiverRequest());

  const headers: Record<string, string> = {
    'x-api-key': apiKey,
  };

  return axios
    .get(`${config.apiUrl}/waivers/${waiverId}`, {
      headers,
    })
    .then((response) => {
      dispatch(fetchWaiverSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchWaiverFailure(err.message));
    });
};

const FETCH_WAIVERS_REQUEST = 'FETCH_WAIVERS_REQUEST';
const FETCH_WAIVERS_SUCCESS = 'FETCH_WAIVERS_SUCCESS';
const FETCH_WAIVERS_FAILURE = 'FETCH_WAIVERS_FAILURE';

const fetchWaiversRequest = () => createAction(FETCH_WAIVERS_REQUEST);
const fetchWaiversSuccess = (response: { waivers: Waiver[] }) =>
  createAction(FETCH_WAIVERS_SUCCESS, response);
const fetchWaiversFailure = (err: string) => createAction(FETCH_WAIVERS_FAILURE, err);

export const fetchWaivers = (apiKey: string, reservationId: string, contentLanguage: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchWaiversRequest());

  const headers: Record<string, string> = {
    'x-api-key': apiKey,
    'accept-language': contentLanguage,
  };

  return axios
    .get(`${config.apiUrl}/waivers`, {
      params: {
        reservation_id: reservationId,
      },
      headers,
    })
    .then((response) => {
      dispatch(fetchWaiversSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchWaiversFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchWaiverRequest>
  | ReturnType<typeof fetchWaiverSuccess>
  | ReturnType<typeof fetchWaiverFailure>
  | ReturnType<typeof fetchWaiversRequest>
  | ReturnType<typeof fetchWaiversSuccess>
  | ReturnType<typeof fetchWaiversFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case WAIVER_FAILURE:
    case FETCH_WAIVERS_FAILURE:
      return action.payload;
    case WAIVER_REQUEST:
    case WAIVER_SUCCESS:
    case FETCH_WAIVERS_REQUEST:
    case FETCH_WAIVERS_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: Waiver[] | null = null, action: Action) => {
  switch (action.type) {
    case WAIVER_SUCCESS:
      return action.payload ? [action.payload] : null;
    case FETCH_WAIVERS_SUCCESS:
      return action.payload?.waivers ?? [];
    case WAIVER_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface WaiversState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
}

export default combineReducers({
  error,
  all,
});
