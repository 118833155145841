import { buildCookieString } from './buildCookieString';

export function getCookieConsentValue(): string | null {
  if (typeof document === 'undefined') {
    return null;
  }

  // Check URL params to see if we already have cookie consent
  const urlParams = new URLSearchParams(window.location.search);
  const urlCookieConsent = urlParams.get('_ct');
  if (urlCookieConsent) {
    document.cookie = buildCookieString('CookieConsent', urlCookieConsent, 90);
    return urlCookieConsent;
  }

  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('CookieConsent=')) {
      return cookie.substring('CookieConsent='.length);
    }
  }
  return null;
}
