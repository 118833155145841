import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { InstantWinEvent } from 'models/instantWinEvent';
import { createAction } from 'ducks/actionHelpers';

const FETCH_INSTANT_WIN_EVENT_REQUEST = 'FETCH_INSTANT_WIN_EVENT_REQUEST';
const FETCH_INSTANT_WIN_EVENT_SUCCESS = 'FETCH_INSTANT_WIN_EVENT_SUCCESS';
const FETCH_INSTANT_WIN_EVENT_FAILURE = 'FETCH_INSTANT_WIN_EVENT_FAILURE';

const fetchInstantWinEventRequest = () => createAction(FETCH_INSTANT_WIN_EVENT_REQUEST);
const fetchInstantWinEventSuccess = (response: InstantWinEvent) =>
  createAction(FETCH_INSTANT_WIN_EVENT_SUCCESS, response);
const fetchInstantWinEventFailure = (err: string) =>
  createAction(FETCH_INSTANT_WIN_EVENT_FAILURE, err);

export const fetchInstantWinEvent = (
  apiKey: string,
  instantWinEventId: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchInstantWinEventRequest());

  const path = `${config.apiUrl}/instantwinevents/${instantWinEventId}`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchInstantWinEventSuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchInstantWinEventFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchInstantWinEventRequest>
  | ReturnType<typeof fetchInstantWinEventSuccess>
  | ReturnType<typeof fetchInstantWinEventFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_INSTANT_WIN_EVENT_FAILURE:
      return action.payload;
    case FETCH_INSTANT_WIN_EVENT_REQUEST:
    case FETCH_INSTANT_WIN_EVENT_SUCCESS:
      return '';
    default:
      return state;
  }
};

const instantWinEvent = (state: InstantWinEvent | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_INSTANT_WIN_EVENT_SUCCESS:
      return action.payload;
    case FETCH_INSTANT_WIN_EVENT_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface InstantWinEventState {
  error: ReturnType<typeof error>;
  instantWinEvent: ReturnType<typeof instantWinEvent>;
}

export default combineReducers({
  error,
  instantWinEvent,
});
