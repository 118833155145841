import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import { RedeemStampRallyRequest, GuidanceStampRallyRedemption } from 'models/guidanceStampRally';

// Actions
const REDEEM_STAMP_RALLY_REQUEST = 'REDEEM_STAMP_RALLY_REQUEST';
export const REDEEM_STAMP_RALLY_SUCCESS = 'REDEEM_STAMP_RALLY_SUCCESS';
const REDEEM_STAMP_RALLY_FAILURE = 'REDEEM_STAMP_RALLY_FAILURE';

// Action creators
const redeemStampRallyRequest = (payload: RedeemStampRallyRequest) =>
  createAction(REDEEM_STAMP_RALLY_REQUEST, payload);
export const redeemStampRallySuccess = (payload: GuidanceStampRallyRedemption) =>
  createAction(REDEEM_STAMP_RALLY_SUCCESS, payload);
const redeemStampRallyFailure = (payload: string) =>
  createAction(REDEEM_STAMP_RALLY_FAILURE, payload);

export const redeemStampRally = (apiKey: string, req: RedeemStampRallyRequest) => (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<any> => {
  dispatch(redeemStampRallyRequest(req));
  return axios
    .post(`${config.apiUrl}/reservations/${req.reservation_id}/redeemstamprally`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(redeemStampRallySuccess(response.data));
    })
    .catch((err) => {
      dispatch(redeemStampRallyFailure(err.message));
    });
};

export const redeemStampRallyForNonReservation = (apiKey: string, req: RedeemStampRallyRequest) => (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<any> => {
  dispatch(redeemStampRallyRequest(req));
  return axios
    .post(`${config.apiUrl}/trackees/${req.tracking_id}/redeemstamprally`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(redeemStampRallySuccess(response.data));
    })
    .catch((err) => {
      dispatch(redeemStampRallyFailure(err.message));
    });
};

export const redeemStampRallyByVisitorIdentifier = (
  apiKey: string,
  req: RedeemStampRallyRequest
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<any> => {
  if (req.reservation_id) {
    return redeemStampRally(apiKey, req)(dispatch);
  }
  if (req.tracking_id) {
    return redeemStampRallyForNonReservation(apiKey, req)(dispatch);
  }
  return Promise.resolve();
};

type Action =
  | ReturnType<typeof redeemStampRallyRequest>
  | ReturnType<typeof redeemStampRallySuccess>
  | ReturnType<typeof redeemStampRallyFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case REDEEM_STAMP_RALLY_FAILURE:
      return action.payload;
    case REDEEM_STAMP_RALLY_REQUEST:
    case REDEEM_STAMP_RALLY_SUCCESS:
      return '';
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case REDEEM_STAMP_RALLY_REQUEST:
      return true;
    case REDEEM_STAMP_RALLY_SUCCESS:
    case REDEEM_STAMP_RALLY_FAILURE:
      return false;
    default:
      return state;
  }
};

export interface StampsState {
  error: ReturnType<typeof error>;
  loading: ReturnType<typeof loading>;
}

export default combineReducers({
  error,
  loading,
});
