import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { CreateMediaDownloadOrderRequest, MediaDownloadOrder } from 'models/mediaDownloadOrder';
import { createAction } from 'ducks/actionHelpers';

// Actions

const CREATE_MEDIA_DOWNLOAD_REQUEST = 'CREATE_MEDIA_DOWNLOAD_REQUEST';
const CREATE_MEDIA_DOWNLOAD_SUCCESS = 'CREATE_MEDIA_DOWNLOAD_SUCCESS';
const CREATE_MEDIA_DOWNLOAD_FAILURE = 'CREATE_MEDIA_DOWNLOAD_FAILURE';

// Action creators

const createMediaDownloadOrderRequest = () => createAction(CREATE_MEDIA_DOWNLOAD_REQUEST);
const createMediaDownloadOrderSuccess = (payload: MediaDownloadOrder) =>
  createAction(CREATE_MEDIA_DOWNLOAD_SUCCESS, payload);
const createMediaDownloadOrderFailure = (payload: string) =>
  createAction(CREATE_MEDIA_DOWNLOAD_FAILURE, payload);

export const createMediaDownloadOrder = (
  apiKey: string,
  req: CreateMediaDownloadOrderRequest,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(createMediaDownloadOrderRequest());

  return axios
    .post(`${config.apiUrl}/mediadownloadorders`, req, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      dispatch(createMediaDownloadOrderSuccess(response.data));
    })
    .catch((err) => {
      dispatch(createMediaDownloadOrderFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof createMediaDownloadOrderRequest>
  | ReturnType<typeof createMediaDownloadOrderSuccess>
  | ReturnType<typeof createMediaDownloadOrderFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case CREATE_MEDIA_DOWNLOAD_FAILURE:
      return action.payload;
    case CREATE_MEDIA_DOWNLOAD_REQUEST:
    case CREATE_MEDIA_DOWNLOAD_SUCCESS:
      return '';
    default:
      return state;
  }
};

const inFlight = (state = false, action: Action) => {
  switch (action.type) {
    case CREATE_MEDIA_DOWNLOAD_REQUEST:
      return true;
    case CREATE_MEDIA_DOWNLOAD_SUCCESS:
    case CREATE_MEDIA_DOWNLOAD_FAILURE:
      return false;
    default:
      return state;
  }
};

const lastCreatedMediaDownloadOrder = (state: MediaDownloadOrder | null = null, action: Action) => {
  switch (action.type) {
    case CREATE_MEDIA_DOWNLOAD_SUCCESS:
      return action.payload;
    case CREATE_MEDIA_DOWNLOAD_REQUEST:
    case CREATE_MEDIA_DOWNLOAD_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface MediaDownloadOrderState {
  error: ReturnType<typeof error>;
  inFlight: ReturnType<typeof inFlight>;
  lastCreatedMediaDownloadOrder: ReturnType<typeof lastCreatedMediaDownloadOrder>;
}

export default combineReducers({
  error,
  inFlight,
  lastCreatedMediaDownloadOrder,
});
