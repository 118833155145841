import { ThunkAction } from 'redux-thunk';
import { AnyAction, combineReducers } from 'redux';
import lodashUniq from 'lodash/uniq';

import { ReduxState } from 'ducks';
import { createAction } from 'ducks/actionHelpers';

// Actions

const SET_WAIT_TIME_NOTIFICATION_IDS = 'SET_WAIT_TIME_NOTIFICATION_IDS';

const waitTimeNotificationPinKeysKey = 'wait-time-notify-ids';

export const setwaitTimeNotificationPinKeys = (ids: string[]) =>
  createAction(SET_WAIT_TIME_NOTIFICATION_IDS, ids);

export const toggleWaitTimeNotificationPinKey = (
  id: string
): ThunkAction<void, ReduxState, Record<string, unknown>, AnyAction> => (
  dispatch,
  getState
): void => {
  const currentIds = getState().mapUserSettings.waitTimeNotificationPinKeys;
  const newIds = currentIds.includes(id)
    ? currentIds.filter((id) => id !== id)
    : [...currentIds, id];
  dispatch(setwaitTimeNotificationPinKeys(newIds));

  // Store in local storage
  localStorage.setItem(waitTimeNotificationPinKeysKey, newIds.join(','));

  // Store in cookie
  document.cookie = `${waitTimeNotificationPinKeysKey}=${newIds.join(',')}`;
};

export const loadMapUserSettings = (): MapUserSettingsState => {
  const lsIds = localStorage.getItem(waitTimeNotificationPinKeysKey)?.split(',') || [];
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${waitTimeNotificationPinKeysKey}=`));
  const cookieIds = cookie?.split('=')[1]?.split(',') || [];

  return {
    waitTimeNotificationPinKeys: lodashUniq([...lsIds, ...cookieIds]),
  };
};

type Action = ReturnType<typeof setwaitTimeNotificationPinKeys>;

// Selector

export const selectwaitTimeNotificationPinKeys = (state: ReduxState) =>
  state.mapUserSettings.waitTimeNotificationPinKeys;

const waitTimeNotificationPinKeys = (state: string[] = [], action: Action) => {
  switch (action.type) {
    case SET_WAIT_TIME_NOTIFICATION_IDS:
      return action.payload;
    default:
      return state;
  }
};

export interface MapUserSettingsState {
  waitTimeNotificationPinKeys: string[];
}

export default combineReducers<MapUserSettingsState>({
  waitTimeNotificationPinKeys,
});
