import React from 'react';
import clsx from 'clsx';

import styles from './CookieConsent.module.css';
import { loadVisitorToken, setVisitorToken } from 'ducks/client/tracking';
import { useDispatch } from 'react-redux';
import { getCookieConsentValue } from 'lib/util/getCookieConsentValue';
import { buildCookieString } from 'lib/util/buildCookieString';
import { useTranslation } from 'react-i18next';

export const CookieConsent: React.FC = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (getCookieConsentValue() == null) {
      setShowModal(true);
    }
  }, []);

  if (!showModal) {
    return null;
  }

  return (
    <div className={styles['container']}>
      <div className={styles['text-container']}>
        <div className={styles['title']}>{t('We use cookies.')}</div>
        {i18n.language === 'en-US' ? (
          <div className={styles['body']}>
            We use cookies to enhance your browsing experience on our website. These cookies help us
            personalize content, analyze site traffic, and provide social media features. For more
            information, please see our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles['link']}
              href="https://ntmg.com/privacy.html"
            >
              Privacy Policy
            </a>
            .
          </div>
        ) : (
          <div className={styles['body']}>
            当サイトでは、ユーザーの利便性向上やカスタマイズされたコンテンツ提供のためにクッキーを使用しています。詳細は
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles['link']}
              href="https://ntmg.jp/privacy.html"
            >
              プライバシーポリシー
            </a>
            をご確認ください。クッキーの使用に同意いただける場合は「同意する」ボタンをクリックしてください。
          </div>
        )}
      </div>
      <div className={styles['actions']}>
        <div
          className={clsx(styles['button'], styles['accept-button'])}
          onClick={() => {
            const exp = new Date();
            exp.setTime(exp.getTime() + 1000 * 60 * 60 * 24 * 90);
            document.cookie = buildCookieString('CookieConsent', 'true', 90);
            dispatch(setVisitorToken(loadVisitorToken()));
            setShowModal(false);
          }}
        >
          {t('Accept All')}
        </div>
        <div
          className={clsx(styles['button'], styles['reject-button'])}
          onClick={() => {
            document.cookie = buildCookieString('CookieConsent', 'false', 90);
            setShowModal(false);
          }}
        >
          {t('Reject All')}
        </div>
      </div>
    </div>
  );
};
