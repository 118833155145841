import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';

// Actions
const FETCH_RESERVATION_VOUCHER_REQUEST = 'FETCH_RESERVATION_VOUCHER_REQUEST';
const FETCH_RESERVATION_VOUCHER_SUCCESS = 'FETCH_RESERVATION_VOUCHER_SUCCESS';
const FETCH_RESERVATION_VOUCHER_FAILURE = 'FETCH_RESERVATION_VOUCHER_FAILURE';

// Action creators
const fetchReservationVoucherRequest = () => createAction(FETCH_RESERVATION_VOUCHER_REQUEST);
const fetchReservationVoucherSuccess = (payload: string) =>
  createAction(FETCH_RESERVATION_VOUCHER_SUCCESS, payload);
const fetchReservationVoucherFailure = (payload: string) =>
  createAction(FETCH_RESERVATION_VOUCHER_FAILURE, payload);

export const fetchReservationVoucher = (apiKey: string, id: string, contentLanguage: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchReservationVoucherRequest());
  return axios
    .get(`${config.apiUrl}/reservations/${id}/voucher`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      responseType: 'text',
    })
    .then((response) => {
      dispatch(fetchReservationVoucherSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchReservationVoucherFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchReservationVoucherRequest>
  | ReturnType<typeof fetchReservationVoucherSuccess>
  | ReturnType<typeof fetchReservationVoucherFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_VOUCHER_FAILURE:
      return action.payload;
    case FETCH_RESERVATION_VOUCHER_REQUEST:
    case FETCH_RESERVATION_VOUCHER_SUCCESS:
      return '';
    default:
      return state;
  }
};

const html = (state: string | undefined = '', action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_VOUCHER_SUCCESS:
      return action.payload;
    case FETCH_RESERVATION_VOUCHER_REQUEST:
    case FETCH_RESERVATION_VOUCHER_FAILURE:
      return '';
    default:
      return state;
  }
};

export interface ReservationVoucherState {
  error: ReturnType<typeof error>;
  html: ReturnType<typeof html>;
}

export default combineReducers({
  error,
  html,
});
