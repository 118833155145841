export interface GuidancePage {
  id: string;
  language: 'EN_US' | 'JA_JP' | 'KO_KR' | 'ZH_CN' | 'ZH_TW';
  title: string;
  description: string;
  status: 'GUIDANCE_PAGE_ACTIVE' | 'GUIDANCE_PAGE_INACTIVE';
  items: GuidancePageItem[];
  product_ids: string[];
  thumbnail_url: string;
  tags: string[];
  stamp_rally_id: string;
  available_date_from: string;
  available_date_to: string;
}

export interface GuidancePageItem {
  title: string;
  description: string;
  body: string;
  location?: GuidancePageLocation;
  coupons: GuidancePageCoupon[];
  index: number;
  thumbnail_url: string;
  should_use_stamp_rally: boolean;
  stamp_rally_label: string;
  key: string;
  product_id: string;
  type: 'NORMAL' | 'PRODUCT';
  stamp_image_url: string;
  autoplay_media_item?: GuidancePageAutoplayMediaItem;
  coupon_redemptions: GuidancePageCouponRedemption[];
}

export interface GuidancePageCouponRedemption {
  coupon_id: string;
  date_time_utc: string;
}

export interface GuidancePageAutoplayMediaItem {
  media_type: 'VIDEO' | 'AUDIO';
  media_url: string;
  activation_distance: number;
}

export interface GuidancePageLocation {
  location_name: string;
  latitude: number;
  longitude: number;
  page_activation_range: number;
}

export interface GuidancePageCoupon {
  id: string;
  title: string;
  description: string;
  redeemed: boolean;
  thumbnail_url?: string;
}

export interface RedeemCouponRequest {
  coupon_id: string;
  reservation_id: string;
  page_id: string;
}

export const addGuidancePageItemIndices = (page: GuidancePage): GuidancePage => {
  return {
    ...page,
    items: page.items?.map((item, idx) => ({
      ...item,
      index: idx,
    })),
  };
};
