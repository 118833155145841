import axios from 'axios';
import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import { ReservationReceipt } from 'models/reservationReceipt';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

// Actions
const FETCH_RESERVATION_RECEIPT_REQUEST = 'FETCH_RESERVATION_RECEIPT_REQUEST';
const FETCH_RESERVATION_RECEIPT_SUCCESS = 'FETCH_RESERVATION_RECEIPT_SUCCESS';
const FETCH_RESERVATION_RECEIPT_FAILURE = 'FETCH_RESERVATION_RECEIPT_FAILURE';

// Action creators
const fetchReservationReceiptRequest = () => createAction(FETCH_RESERVATION_RECEIPT_REQUEST);
const fetchReservationReceiptSuccess = (response: ReservationReceipt) =>
  createAction(FETCH_RESERVATION_RECEIPT_SUCCESS, response);
const fetchReservationReceiptFailure = (err: string) =>
  createAction(FETCH_RESERVATION_RECEIPT_FAILURE, err);

export const fetchReservationReceipt = (
  apiKey: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchReservationReceiptRequest());
  return axios
    .get(`${config.apiUrl}/reservationreceipts/${reservationId}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(fetchReservationReceiptSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchReservationReceiptFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchReservationReceiptRequest>
  | ReturnType<typeof fetchReservationReceiptSuccess>
  | ReturnType<typeof fetchReservationReceiptFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_RECEIPT_FAILURE:
      return action.payload;
    case FETCH_RESERVATION_RECEIPT_REQUEST:
    case FETCH_RESERVATION_RECEIPT_SUCCESS:
      return '';
    default:
      return state;
  }
};

const reservationReceipt = (state: ReservationReceipt | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_RECEIPT_SUCCESS:
      return action.payload;
    case FETCH_RESERVATION_RECEIPT_REQUEST:
    case FETCH_RESERVATION_RECEIPT_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface ReservationReceiptState {
  error: ReturnType<typeof error>;
  reservationReceipt: ReturnType<typeof reservationReceipt>;
}

export default combineReducers({
  error,
  reservationReceipt,
});
