import * as React from 'react';

interface CurrentPosition {
  currentPosition: google.maps.LatLng | null;
  currentPositionRef: React.MutableRefObject<google.maps.LatLng | null>;
  updatedAt: number | null;
}

export const CurrentPositionContext = React.createContext<CurrentPosition>({
  currentPosition: null,
  currentPositionRef: React.createRef(),
  updatedAt: null,
});
