import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { Product } from 'models/product';
import { createAction } from 'ducks/actionHelpers';

// Actions
const RE_FETCH_PRODUCT_REQUEST = 'RE_FETCH_PRODUCT_REQUEST';
export const RE_FETCH_PRODUCT_SUCCESS = 'RE_FETCH_PRODUCT_SUCCESS';
const RE_FETCH_PRODUCT_FAILURE = 'RE_FETCH_PRODUCT_FAILURE';

// Action creators
const reFetchProductRequest = () => createAction(RE_FETCH_PRODUCT_REQUEST);
export const reFetchProductSuccess = (response: Product) =>
  createAction(RE_FETCH_PRODUCT_SUCCESS, response);
const reFetchProductFailure = (err: string) => createAction(RE_FETCH_PRODUCT_FAILURE, err);

export const reFetchProduct = (
  apiKey: string,
  id: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(reFetchProductRequest());
  return axios
    .get(`${config.apiUrl}/products/${id}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(reFetchProductSuccess({ ...response.data, isReFetched: true }));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(reFetchProductFailure('canceled'));
      } else {
        dispatch(reFetchProductFailure(err.message));
      }
    });
};

type Action =
  | ReturnType<typeof reFetchProductRequest>
  | ReturnType<typeof reFetchProductSuccess>
  | ReturnType<typeof reFetchProductFailure>;

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case RE_FETCH_PRODUCT_REQUEST:
      return true;
    case RE_FETCH_PRODUCT_SUCCESS:
    case RE_FETCH_PRODUCT_FAILURE:
      return false;
    default:
      return state;
  }
};

export interface ProductState {
  loading: boolean;
}

export default combineReducers({
  loading,
});
