import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import { ReduxState } from 'ducks';
import { createAction } from 'ducks/actionHelpers';

// Actions

const ADD_BOOKMARK = 'ADD_BOOKMARK';
const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK';

const addBookmarkAction = (productId: string) => createAction(ADD_BOOKMARK, productId);
const removeBookmarkAction = (productId: string) => createAction(REMOVE_BOOKMARK, productId);

const bookmarksKey = 'product-bookmarks';

export const addBookmark = (
  productId: string
): ThunkAction<void, ReduxState, Record<string, unknown>, AnyAction> => (
  dispatch,
  getState
): void => {
  dispatch(addBookmarkAction(productId));

  const bookmarks = getState().bookmarks.join(',');

  // Store in local storage
  localStorage.setItem(bookmarksKey, bookmarks);

  // Store in cookie
  document.cookie = `${bookmarksKey}=${bookmarks}`;
};

export const removeBookmark = (
  productId: string
): ThunkAction<void, ReduxState, Record<string, unknown>, AnyAction> => (
  dispatch,
  getState
): void => {
  dispatch(removeBookmarkAction(productId));

  const bookmarks = getState().bookmarks.join(',');

  // Store in local storage
  localStorage.setItem(bookmarksKey, bookmarks);

  // Store in cookie
  document.cookie = `${bookmarksKey}=${bookmarks}`;
};

export const loadBookmarks = (): string[] => {
  const lsBookmarks = localStorage.getItem(bookmarksKey)?.split(',') || [];
  const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${bookmarksKey}=`));
  const cookieBookmarks = cookie?.split('=')[1]?.split(',') || [];

  return [...new Set([...lsBookmarks, ...cookieBookmarks])];
};

type Action = ReturnType<typeof addBookmarkAction> | ReturnType<typeof removeBookmarkAction>;

// Selector

export const selectBookmarkedProductIds = (state: ReduxState) => state.bookmarks;

const bookmarks = (state: string[] = [], action: Action) => {
  switch (action.type) {
    case ADD_BOOKMARK:
      return state.includes(action.payload) ? state : [...state, action.payload];
    case REMOVE_BOOKMARK:
      return state.filter((item) => item !== action.payload);
    default:
      return state;
  }
};

export type BookmarksState = string[];

export default bookmarks;
