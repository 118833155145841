import * as React from 'react';

import { IdProvider } from 'models/customer';

export interface CustomerTokenType {
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
  expiresAt?: number;
  idProvider?: IdProvider | null;
  apiKey?: string;
  loading?: boolean;
}

export interface CustomerTokenContextType extends CustomerTokenType {
  logout: () => void;
  setToken: (props: CustomerTokenType) => void;
}

export const CustomerTokenContext = React.createContext<CustomerTokenContextType>({
  idToken: '',
  accessToken: '',
  refreshToken: '',
  expiresAt: 0,
  idProvider: null,
  apiKey: '',
  logout: () => undefined,
  setToken: () => undefined,
  loading: true,
});
