import {
  developmentFeatureFlags,
  releaseFeatureFlags,
  stgDemoFeatureFlags,
  FeatureFlags,
} from './featureFlags';

type EnvironmentName = 'development' | 'staging' | 'production';

interface OAuth2Config {
  clientId: string;
  clientSecret: string;
  scopes: string[];
}

interface ConfigMap {
  envName: EnvironmentName;
  apiUrl: string;
  stripeUSDKey: string;
  stripeJPYKey: string;
  stripeJPYExpressKey: string;
  stripeHIS3DSKey: string;
  googleAnalyticsTag: string;
  ogpParserEndPoint: string;
  googleAuth: OAuth2Config;
  facebookAuth: OAuth2Config;
  gmoScriptUrl?: string;
  gmoShopId?: string;
  webSocketEndpointUrl: string;
}

const configMaps: {
  development: ConfigMap & FeatureFlags;
  staging: ConfigMap & FeatureFlags;
  production: ConfigMap & FeatureFlags;
} = {
  development: {
    envName: 'development',
    apiUrl: 'https://api.dev.ntmg.com/v1',
    stripeUSDKey: 'pk_test_vrNTE42OAi4zZj4xnPVgsFxe',
    stripeJPYKey: 'pk_test_2TKw7zhBdniZEVO9jc5zA4lA000LiRGO7f',
    stripeJPYExpressKey:
      'pk_test_51J0IlTBrTpDuVJFbdk3w7yKHujjR6QLJZStwXcWmmolBpMTApWB3lVnu9mGOZU52v6aT7HCgN7Mrw6lmqbsiZMXg00JTdBsxRk',
    stripeHIS3DSKey:
      'pk_test_51MszO3Cdk9AyStWtht7GyjOVCybfRGSfP7M1PM0gubGrBAscktUh1sfn6H2JwttZsNMWp9g6wsTvOdyJVHU0Wknu00NNtrUtNo',
    googleAnalyticsTag: 'UA-154622925-1',
    ogpParserEndPoint:
      'https://tfs15glil4.execute-api.us-west-1.amazonaws.com/ogp-parser-development',
    googleAuth: {
      clientId: '1008341547371-uj10fg40279dn6q9a4dfv1ts2gvn4rp7.apps.googleusercontent.com',
      clientSecret: '',
      scopes: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ],
    },
    facebookAuth: {
      clientId: '1807000496339702',
      clientSecret: '', //Once an empty character
      scopes: ['email', 'public_profile'],
    },
    gmoScriptUrl: 'https://stg.static.mul-pay.jp/payment/js/mp-token.js',
    gmoShopId: 'tshop00066080',
    webSocketEndpointUrl: 'wss://rctaas6zh6.execute-api.us-west-1.amazonaws.com/development',
    ...developmentFeatureFlags,
  },
  staging: {
    envName: 'staging',
    apiUrl: 'https://api.stg.ntmg.com/v1',
    stripeUSDKey: 'pk_test_vrNTE42OAi4zZj4xnPVgsFxe',
    stripeJPYKey: 'pk_test_2TKw7zhBdniZEVO9jc5zA4lA000LiRGO7f',
    stripeJPYExpressKey:
      'pk_test_51J0IlTBrTpDuVJFbdk3w7yKHujjR6QLJZStwXcWmmolBpMTApWB3lVnu9mGOZU52v6aT7HCgN7Mrw6lmqbsiZMXg00JTdBsxRk',
    stripeHIS3DSKey:
      'pk_test_51MszO3Cdk9AyStWtht7GyjOVCybfRGSfP7M1PM0gubGrBAscktUh1sfn6H2JwttZsNMWp9g6wsTvOdyJVHU0Wknu00NNtrUtNo',
    googleAnalyticsTag: 'UA-154622925-1',
    ogpParserEndPoint:
      'https://tfs15glil4.execute-api.us-west-1.amazonaws.com/ogp-parser-development',
    googleAuth: {
      clientId: '1008341547371-uj10fg40279dn6q9a4dfv1ts2gvn4rp7.apps.googleusercontent.com',
      clientSecret: '',
      scopes: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ],
    },
    facebookAuth: {
      clientId: '554692133306072',
      clientSecret: '', //Once an empty character
      scopes: ['email', 'public_profile'],
    },
    gmoScriptUrl: 'https://stg.static.mul-pay.jp/payment/js/mp-token.js',
    gmoShopId: 'tshop00066080',
    webSocketEndpointUrl: 'wss://snbjll5h9l.execute-api.ap-southeast-2.amazonaws.com/staging',
    ...releaseFeatureFlags,
    ...stgDemoFeatureFlags,
  },
  production: {
    envName: 'production',
    apiUrl: 'https://api.ntmg.com/v1',
    stripeUSDKey: 'pk_live_EF02izkIrPPFrHw31wlBzLub',
    stripeJPYKey: 'pk_live_6coR0QKva5NxjWIjc8Tfe1aQ00yZna3AOG',
    stripeJPYExpressKey:
      'pk_live_51J0IlTBrTpDuVJFb78xXeLMEct1VRRiEWpezT00LlOpYlFM4pty21C5U7FMT182w5B6D3ZRGvkqKtetgYCUUFlkz009MzYg8TS',
    stripeHIS3DSKey:
      'pk_live_51MszO3Cdk9AyStWthWXljCwBr6jDfP6EPDcPD41tvxsfborfSg5CY9X3tL6byR9p0CrRfEOJiSK8bmvkD7ByjPUZ00msFbNICw',
    googleAnalyticsTag: 'UA-154613919-1',
    ogpParserEndPoint:
      'https://tfs15glil4.execute-api.us-west-1.amazonaws.com/ogp-parser-development',
    googleAuth: {
      clientId: '1008341547371-uj10fg40279dn6q9a4dfv1ts2gvn4rp7.apps.googleusercontent.com',
      clientSecret: '',
      scopes: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ],
    },
    facebookAuth: {
      clientId: '554692133306072',
      clientSecret: '', //Once an empty character
      scopes: ['email', 'public_profile'],
    },
    gmoScriptUrl: 'https://static.mul-pay.jp/payment/js/mp-token.js',
    gmoShopId: '9200007083308',
    webSocketEndpointUrl: 'wss://mklusxtnt6.execute-api.ap-southeast-2.amazonaws.com/production',
    ...releaseFeatureFlags,
  },
};

const config = configMaps[(process.env.NEXT_PUBLIC_ENV_NAME as EnvironmentName) ?? 'development'];

// Allow overriding API URL with custom value for local testing.
if (process.env.NEXT_PUBLIC_API_URL) {
  config.apiUrl = process.env.NEXT_PUBLIC_API_URL;
}

export default config;
