import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import { LeaveFootprintRequest } from 'models/reservation';

// Actions
const LEAVE_FOOTPRINT_REQUEST = 'LEAVE_FOOTPRINT_REQUEST';
export const LEAVE_FOOTPRINT_SUCCESS = 'LEAVE_FOOTPRINT_SUCCESS';
const LEAVE_FOOTPRINT_FAILURE = 'LEAVE_FOOTPRINT_FAILURE';

// Action creators
const leaveFootprintRequest = (payload: LeaveFootprintRequest) =>
  createAction(LEAVE_FOOTPRINT_REQUEST, payload);
export const leaveFootprintSuccess = (payload: LeaveFootprintRequest) =>
  createAction(LEAVE_FOOTPRINT_SUCCESS, payload);
const leaveFootprintFailure = (payload: string) => createAction(LEAVE_FOOTPRINT_FAILURE, payload);

export const leaveFootprint = (apiKey: string, req: LeaveFootprintRequest) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(leaveFootprintRequest(req));
  return axios
    .post(`${config.apiUrl}/reservations/${req.reservation_id}/footprint`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then(() => {
      dispatch(leaveFootprintSuccess(req));
    })
    .catch((err) => {
      dispatch(leaveFootprintFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof leaveFootprintRequest>
  | ReturnType<typeof leaveFootprintSuccess>
  | ReturnType<typeof leaveFootprintFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case LEAVE_FOOTPRINT_FAILURE:
      return action.payload;
    case LEAVE_FOOTPRINT_REQUEST:
    case LEAVE_FOOTPRINT_SUCCESS:
      return '';
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case LEAVE_FOOTPRINT_REQUEST:
      return true;
    case LEAVE_FOOTPRINT_SUCCESS:
    case LEAVE_FOOTPRINT_FAILURE:
      return false;
    default:
      return state;
  }
};

export interface FootprintsState {
  error: ReturnType<typeof error>;
  loading: ReturnType<typeof loading>;
}

export default combineReducers({
  error,
  loading,
});
