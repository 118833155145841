import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { CreateWaiverRequest, Waiver } from 'models/waiver';
import { createAction } from 'ducks/actionHelpers';

// Actions
const CREATE_WAIVER_REQUEST = 'CREATE_WAIVER_REQUEST';
const CREATE_WAIVER_SUCCESS = 'CREATE_WAIVER_SUCCESS';
const CREATE_WAIVER_FAILURE = 'CREATE_WAIVER_FAILURE';

// Action creators
const createWaiverRequest = () => createAction(CREATE_WAIVER_REQUEST);
const createWaiverSuccess = (response: Waiver) => createAction(CREATE_WAIVER_SUCCESS, response);
const createWaiverFailure = (err: string) => createAction(CREATE_WAIVER_FAILURE, err);

export const createWaiver = (apiKey: string, req: CreateWaiverRequest) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(createWaiverRequest());
  return axios
    .post(`${config.apiUrl}/waivers`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(createWaiverSuccess(response.data));
    })
    .catch((err) => {
      dispatch(createWaiverFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof createWaiverRequest>
  | ReturnType<typeof createWaiverSuccess>
  | ReturnType<typeof createWaiverFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case CREATE_WAIVER_FAILURE:
      return action.payload;
    case CREATE_WAIVER_REQUEST:
    case CREATE_WAIVER_SUCCESS:
      return '';
    default:
      return state;
  }
};

const waiver = (state: Waiver | null = null, action: Action) => {
  switch (action.type) {
    case CREATE_WAIVER_SUCCESS:
      return action.payload;
    case CREATE_WAIVER_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface WaiversState {
  error: ReturnType<typeof error>;
  waiver: ReturnType<typeof waiver>;
}

export default combineReducers({
  error,
  waiver,
});
