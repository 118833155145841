import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { Attribution, Review, ReviewRating } from 'models/review';
import { ContentLanguage } from 'models/settings';
import { createAction } from 'ducks/actionHelpers';

// Actions
const REVIEWS_REQUEST = 'REVIEWS_REQUEST';
export const REVIEWS_SUCCESS = 'REVIEWS_SUCCESS';
const REVIEWS_FAILURE = 'REVIEWS_FAILURE';
const FETCH_PAGE_OF_REVIEWS_REQUEST = 'FETCH_PAGE_OF_REVIEWS_REQUEST';
const FETCH_PAGE_OF_REVIEWS_SUCCESS = 'FETCH_PAGE_OF_REVIEWS_SUCCESS';
const FETCH_PAGE_OF_REVIEWS_FAILURE = 'FETCH_PAGE_OF_REVIEWS_FAILURE';

// Action creators
const reviewsRequest = () => createAction(REVIEWS_REQUEST);
const reviewsSuccess = (payload: Review[]) => createAction(REVIEWS_SUCCESS, payload);
const reviewsFailure = (payload: string) => createAction(REVIEWS_FAILURE, payload);

// fetchReviews will clear any reviews that we have already loaded before fetching new ones
export const fetchReviews = (
  apiKey: string,
  contentLanguage: string,
  params?: {
    productIds?: string[];
    ratings?: ReviewRating[];
    languagesFilter?: ContentLanguage[];
    attributions?: Attribution[];
    months?: number[];
    pageSize?: number;
  }
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(reviewsRequest());
  return axios
    .get(`${config.apiUrl}/reviews`, {
      params: {
        product_ids: params?.productIds,
        ratings: params?.ratings,
        languages: params?.languagesFilter,
        attributions: params?.attributions,
        months: params?.months,
        statuses: ['PUBLISHED'],
        page_size: params?.pageSize,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(reviewsSuccess(response.data.reviews));
    })
    .catch((err) => {
      dispatch(reviewsFailure(err.message));
    });
};

const fetchPageOfReviewsRequest = () => createAction(FETCH_PAGE_OF_REVIEWS_REQUEST);
const fetchPageOfReviewsSuccess = (payload: Review[]) =>
  createAction(FETCH_PAGE_OF_REVIEWS_SUCCESS, payload);
const fetchPageOfReviewsFailure = (payload: string) =>
  createAction(FETCH_PAGE_OF_REVIEWS_FAILURE, payload);

// fetchPageOfReviews will append any newly-fetched reviews to the existing list of reviews
export const fetchPageOfReviews = (
  apiKey: string,
  contentLanguage: string,
  params?: {
    productIds?: string[];
    ratings?: ReviewRating[];
    languagesFilter?: ContentLanguage[];
    attributions?: Attribution[];
    months?: number[];
    offset?: number;
    pageSize?: number;
  }
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchPageOfReviewsRequest());
  return axios
    .get(`${config.apiUrl}/reviews`, {
      params: {
        product_ids: params?.productIds,
        ratings: params?.ratings,
        languages: params?.languagesFilter,
        attributions: params?.attributions,
        months: params?.months,
        statuses: ['PUBLISHED'],
        offset: params?.offset,
        page_size: params?.pageSize,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(fetchPageOfReviewsSuccess(response.data.reviews));
    })
    .catch((err) => {
      dispatch(fetchPageOfReviewsFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof reviewsRequest>
  | ReturnType<typeof reviewsSuccess>
  | ReturnType<typeof reviewsFailure>
  | ReturnType<typeof fetchPageOfReviewsRequest>
  | ReturnType<typeof fetchPageOfReviewsSuccess>
  | ReturnType<typeof fetchPageOfReviewsFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case REVIEWS_FAILURE:
    case FETCH_PAGE_OF_REVIEWS_FAILURE:
      return action.payload;
    case REVIEWS_REQUEST:
    case REVIEWS_SUCCESS:
    case FETCH_PAGE_OF_REVIEWS_REQUEST:
    case FETCH_PAGE_OF_REVIEWS_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: Review[] | null = null, action: Action) => {
  switch (action.type) {
    case REVIEWS_SUCCESS:
      return action.payload;
    case FETCH_PAGE_OF_REVIEWS_SUCCESS:
      return [
        ...(state ?? []),
        ...action.payload.filter((review: Review) => !state?.find((r) => r.id === review.id)),
      ];
    case REVIEWS_FAILURE:
      return null;
    default:
      return state;
  }
};

const lastFetchedPage = (state: Review[] | null = null, action: Action): Review[] | null => {
  switch (action.type) {
    case FETCH_PAGE_OF_REVIEWS_SUCCESS:
    case REVIEWS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case REVIEWS_REQUEST:
    case FETCH_PAGE_OF_REVIEWS_REQUEST:
      return true;
    case REVIEWS_SUCCESS:
    case REVIEWS_FAILURE:
    case FETCH_PAGE_OF_REVIEWS_SUCCESS:
    case FETCH_PAGE_OF_REVIEWS_FAILURE:
      return false;
    default:
      return state;
  }
};

export interface ReviewsState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
  lastFetchedPage: ReturnType<typeof lastFetchedPage>;
  loading: ReturnType<typeof loading>;
}

export default combineReducers({
  error,
  all,
  lastFetchedPage,
  loading,
});
