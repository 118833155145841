import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { ETicketQrCode } from '../../models/eTicketQrCode';
import { createAction } from '../actionHelpers';

// Actions
const E_TICKET_QR_CODE_REQUEST = 'E_TICKET_QR_CODE_REQUEST';
const E_TICKET_QR_CODE_SUCCESS = 'E_TICKET_QR_CODE_SUCCESS';
const E_TICKET_QR_CODE_FAILURE = 'E_TICKET_QR_CODE_FAILURE';

// Action creators
const eTicketQrCodeRequest = () => createAction(E_TICKET_QR_CODE_REQUEST);
const eTicketQrCodeSuccess = (payload: ETicketQrCode) =>
  createAction(E_TICKET_QR_CODE_SUCCESS, payload);
const eTicketQrCodeFailure = (payload: string) => createAction(E_TICKET_QR_CODE_FAILURE, payload);

export const fetchETicketQrCode = (
  apiKey: string,
  id: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<any> => {
  dispatch(eTicketQrCodeRequest());

  return axios
    .get(`${config.apiUrl}/eticketqrcodes/${id}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(eTicketQrCodeSuccess(response.data));
    })
    .catch((err) => {
      dispatch(eTicketQrCodeFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof eTicketQrCodeRequest>
  | ReturnType<typeof eTicketQrCodeSuccess>
  | ReturnType<typeof eTicketQrCodeFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case E_TICKET_QR_CODE_FAILURE:
      return action.payload;
    case E_TICKET_QR_CODE_REQUEST:
    case E_TICKET_QR_CODE_SUCCESS:
      return '';
    default:
      return state;
  }
};

const eTicketQrCode = (state: ETicketQrCode | null = null, action: Action) => {
  switch (action.type) {
    case E_TICKET_QR_CODE_SUCCESS:
      return action.payload;
    case E_TICKET_QR_CODE_REQUEST:
    case E_TICKET_QR_CODE_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface ETicketQrCodeState {
  error: ReturnType<typeof error>;
  eTicketQrCode: ReturnType<typeof eTicketQrCode>;
}

export default combineReducers({
  error,
  eTicketQrCode,
});
