export function buildCookieString(key: string, value: string, expirationInDays?: number): string {
  let cookieString = `${key}=${value};`;

  if (expirationInDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationInDays);
    cookieString += `expires=${expirationDate.toUTCString()};`;
  }

  return cookieString + 'path=/';
}
