import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { SurveyTemplate } from 'models/surveyTemplate';
import { createAction } from 'ducks/actionHelpers';

// Actions
const SURVEY_TEMPLATE_REQUEST = 'SURVEY_TEMPLATE_REQUEST';
const SURVEY_TEMPLATE_SUCCESS = 'SURVEY_TEMPLATE_SUCCESS';
const SURVEY_TEMPLATE_FAILURE = 'SURVEY_TEMPLATE_FAILURE';

// Action creators
const fetchSurveyTemplateRequest = () => createAction(SURVEY_TEMPLATE_REQUEST);
const fetchSurveyTemplateSuccess = (response: SurveyTemplate) =>
  createAction(SURVEY_TEMPLATE_SUCCESS, response);
const fetchSurveyTemplateFailure = (err: string) => createAction(SURVEY_TEMPLATE_FAILURE, err);

export const fetchSurveyTemplate = (
  apiKey: string,
  surveyTemplateId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchSurveyTemplateRequest());

  const headers: Record<string, string> = {
    'x-api-key': apiKey,
    'accept-language': contentLanguage,
  };

  return axios
    .get(`${config.apiUrl}/surveytemplates/${surveyTemplateId}`, {
      headers,
    })
    .then((response) => {
      dispatch(fetchSurveyTemplateSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchSurveyTemplateFailure(err.message));
    });
};

export const fetchReservationSurveyTemplate = (
  apiKey: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchSurveyTemplateRequest());

  const path = `${config.apiUrl}/reservations/${reservationId}/surveytemplates`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;

      const surveyTemplate = (payload ?? []).length > 0 ? payload[0] : null;
      dispatch(fetchSurveyTemplateSuccess(surveyTemplate));
    })
    .catch((err) => {
      dispatch(fetchSurveyTemplateFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchSurveyTemplateRequest>
  | ReturnType<typeof fetchSurveyTemplateSuccess>
  | ReturnType<typeof fetchSurveyTemplateFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case SURVEY_TEMPLATE_FAILURE:
      return action.payload;
    case SURVEY_TEMPLATE_REQUEST:
    case SURVEY_TEMPLATE_SUCCESS:
      return '';
    default:
      return state;
  }
};

const surveyTemplate = (state: SurveyTemplate | null = null, action: Action) => {
  switch (action.type) {
    case SURVEY_TEMPLATE_SUCCESS:
      return action.payload;
    case SURVEY_TEMPLATE_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface SurveyTemplatesState {
  error: ReturnType<typeof error>;
  surveyTemplate: ReturnType<typeof surveyTemplate>;
}

export default combineReducers({
  error,
  surveyTemplate,
});
