import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { escapeReservationFieldNames } from 'lib/util/escapeFieldName';
import { Reservation } from '../../models/reservation';
import { createAction } from '../actionHelpers';

// Actions
const FETCH_RESERVATION_REQUEST = 'FETCH_RESERVATION_REQUEST';
const FETCH_RESERVATION_SUCCESS = 'FETCH_RESERVATION_SUCCESS';
const FETCH_RESERVATION_FAILURE = 'FETCH_RESERVATION_FAILURE';
const FETCH_RESERVATIONS_BY_IDS_REQUEST = 'FETCH_RESERVATIONS_BY_IDS_REQUEST';
const FETCH_RESERVATIONS_BY_IDS_SUCCESS = 'FETCH_RESERVATIONS_BY_IDS_SUCCESS';
const FETCH_RESERVATIONS_BY_IDS_FAILURE = 'FETCH_RESERVATIONS_BY_IDS_FAILURE';

// Action creators
const fetchReservationRequest = () => createAction(FETCH_RESERVATION_REQUEST);
const fetchReservationSuccess = (payload: Reservation) =>
  createAction(FETCH_RESERVATION_SUCCESS, payload);
const fetchReservationFailure = (payload: string) =>
  createAction(FETCH_RESERVATION_FAILURE, payload);

export const fetchReservation = (apiKey: string, id: string, contentLanguage: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchReservationRequest());
  return axios
    .get(`${config.apiUrl}/reservations/${id}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(fetchReservationSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchReservationFailure(err.message));
    });
};

const fetchReservationsByIdsRequest = () => createAction(FETCH_RESERVATIONS_BY_IDS_REQUEST);
const fetchReservationsByIdsSuccess = (payload: Reservation[]) =>
  createAction(FETCH_RESERVATIONS_BY_IDS_SUCCESS, payload);
const fetchReservationsByIdsFailure = (payload: string) =>
  createAction(FETCH_RESERVATIONS_BY_IDS_FAILURE, payload);

export const fetchReservationsByIds = (
  apiKey: string,
  reservationIds: string[],
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<any> => {
  dispatch(fetchReservationsByIdsRequest());
  const requests = reservationIds.map((id) =>
    axios.get(`${config.apiUrl}/reservations/${id}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
  );
  return axios
    .all(requests)
    .then((responses) => {
      dispatch(fetchReservationsByIdsSuccess(responses.map((response) => response.data)));
    })
    .catch((err) => {
      dispatch(fetchReservationsByIdsFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchReservationRequest>
  | ReturnType<typeof fetchReservationSuccess>
  | ReturnType<typeof fetchReservationFailure>
  | ReturnType<typeof fetchReservationsByIdsRequest>
  | ReturnType<typeof fetchReservationsByIdsSuccess>
  | ReturnType<typeof fetchReservationsByIdsFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_FAILURE:
    case FETCH_RESERVATIONS_BY_IDS_FAILURE:
      return action.payload;
    case FETCH_RESERVATION_REQUEST:
    case FETCH_RESERVATION_SUCCESS:
    case FETCH_RESERVATIONS_BY_IDS_REQUEST:
    case FETCH_RESERVATIONS_BY_IDS_SUCCESS:
      return '';
    default:
      return state;
  }
};

const reservation = (state: Reservation | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_SUCCESS:
      return escapeReservationFieldNames(action.payload);
    case FETCH_RESERVATION_REQUEST:
    case FETCH_RESERVATION_FAILURE:
      return null;
    default:
      return state;
  }
};

const reservationsByIds = (state: Reservation[] = [], action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_BY_IDS_SUCCESS:
      return action.payload;
    case FETCH_RESERVATIONS_BY_IDS_REQUEST:
    case FETCH_RESERVATIONS_BY_IDS_FAILURE:
      return [];
    default:
      return state;
  }
};

export interface ReservationState {
  error: ReturnType<typeof error>;
  reservation: ReturnType<typeof reservation>;
  reservationsByIds: ReturnType<typeof reservationsByIds>;
}

export default combineReducers({
  error,
  reservation,
  reservationsByIds,
});
