import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { ListProductsResponse, ProductSummary } from '../../models/product';
import { createAction } from '../actionHelpers';

// Actions
const AVAILABLE_PRODUCT_SUMMARIES_REQUEST = 'AVAILABLE_PRODUCT_SUMMARIES_REQUEST';
const AVAILABLE_PRODUCT_SUMMARIES_SUCCESS = 'AVAILABLE_PRODUCT_SUMMARIES_SUCCESS';
const AVAILABLE_PRODUCT_SUMMARIES_FAILURE = 'AVAILABLE_PRODUCT_SUMMARIES_FAILURE';

// Action creators
const availableProductSummariesRequest = () => createAction(AVAILABLE_PRODUCT_SUMMARIES_REQUEST);
const availableProductSummariesSuccess = (response: ListProductsResponse) =>
  createAction(AVAILABLE_PRODUCT_SUMMARIES_SUCCESS, response);
const availableProductSummariesFailure = (err: string) =>
  createAction(AVAILABLE_PRODUCT_SUMMARIES_FAILURE, err);

export const fetchAvailableProductSummaries = (
  apiKey: string,
  contentLanguage: string,
  availableDate: string,
  availableAdultCount: number,
  availableChildCount: number,
  availableInfantCount: number
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(availableProductSummariesRequest());
  return axios
    .get(`${config.apiUrl}/products`, {
      params: {
        availability_filter_start_date_local: availableDate,
        availability_filter_adult_count: availableAdultCount,
        availability_filter_child_count: availableChildCount,
        availability_filter_infant_count: availableInfantCount,
        availability_filter_confirmation_types: ['INSTANT', 'REQUEST'],
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(availableProductSummariesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(availableProductSummariesFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof availableProductSummariesFailure>
  | ReturnType<typeof availableProductSummariesRequest>
  | ReturnType<typeof availableProductSummariesSuccess>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case AVAILABLE_PRODUCT_SUMMARIES_FAILURE:
      return action.payload;
    case AVAILABLE_PRODUCT_SUMMARIES_REQUEST:
    case AVAILABLE_PRODUCT_SUMMARIES_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: ProductSummary[] = [], action: Action) => {
  switch (action.type) {
    case AVAILABLE_PRODUCT_SUMMARIES_SUCCESS:
      return action.payload.products;
    case AVAILABLE_PRODUCT_SUMMARIES_FAILURE:
      return [];
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case AVAILABLE_PRODUCT_SUMMARIES_SUCCESS:
    case AVAILABLE_PRODUCT_SUMMARIES_FAILURE:
      return false;
    case AVAILABLE_PRODUCT_SUMMARIES_REQUEST:
      return true;
    default:
      return state;
  }
};

export interface AvailableProductSummariesState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
  loading: ReturnType<typeof loading>;
}

export default combineReducers({
  error,
  all,
  loading,
});
