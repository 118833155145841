import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { CustomPage } from 'models/customPage';
import { createAction } from 'ducks/actionHelpers';

// Actions
const CUSTOM_PAGES_REQUEST = 'CUSTOM_PAGES_REQUEST';
export const CUSTOM_PAGES_SUCCESS = 'CUSTOM_PAGES_SUCCESS';
const CUSTOM_PAGES_FAILURE = 'CUSTOM_PAGES_FAILURE';

// Action creators
const customPagesRequest = () => createAction(CUSTOM_PAGES_REQUEST);
const customPagesSuccess = (payload: CustomPage[]) => createAction(CUSTOM_PAGES_SUCCESS, payload);
const customPagesFailure = (payload: string) => createAction(CUSTOM_PAGES_FAILURE, payload);

export const fetchCustomPages = (apiKey: string, contentLanguage: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(customPagesRequest());
  return axios
    .get(
      `${
        config.apiUrl
      }/custompages?status=ACTIVE&content_language=${contentLanguage
        .toUpperCase()
        .replace('-', '_')}`,
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      dispatch(customPagesSuccess(response.data.custom_pages));
    })
    .catch((err) => {
      dispatch(customPagesFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof customPagesRequest>
  | ReturnType<typeof customPagesSuccess>
  | ReturnType<typeof customPagesFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case CUSTOM_PAGES_FAILURE:
      return action.payload;
    case CUSTOM_PAGES_REQUEST:
    case CUSTOM_PAGES_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: CustomPage[] | null = null, action: Action) => {
  switch (action.type) {
    case CUSTOM_PAGES_SUCCESS:
      return action.payload;
    case CUSTOM_PAGES_REQUEST:
    case CUSTOM_PAGES_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface CustomPagesState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
}

export default combineReducers({
  error,
  all,
});
