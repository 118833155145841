import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';

export const useCustomerEventLoggingEnabled = (): boolean => {
  const enabled = useSelector(
    (state: ReduxState) =>
      state.server.settings.all?.subscriptions?.some(
        (subscription: any) =>
          (subscription.key === 'feature-journey-analytics' ||
            subscription.key === 'feature-marketing-automation') &&
          subscription.status === 'ACTIVE'
      ) ?? false
  );

  return enabled;
};

export const useJourneyAnalyticsEnabled = (): boolean => {
  const enabled = useSelector(
    (state: ReduxState) =>
      state.server.settings.all?.subscriptions?.some(
        (subscription: any) =>
          subscription.key === 'feature-journey-analytics' && subscription.status === 'ACTIVE'
      ) ?? false
  );

  return enabled;
};
