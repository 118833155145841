import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { ListProductsResponse, ProductSummary } from '../../models/product';
import { createAction } from '../actionHelpers';

// Actions
const PARTNERSHIP_PRODUCT_SUMMARIES_REQUEST = 'PARTNERSHIP_PRODUCT_SUMMARIES_REQUEST';
const PARTNERSHIP_PRODUCT_SUMMARIES_SUCCESS = 'PARTNERSHIP_PRODUCT_SUMMARIES_SUCCESS';
const PARTNERSHIP_PRODUCT_SUMMARIES_FAILURE = 'PARTNERSHIP_PRODUCT_SUMMARIES_FAILURE';
const PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_REQUEST =
  'PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_REQUEST';
const PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_SUCCESS =
  'PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_SUCCESS';
const PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_FAILURE =
  'PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_FAILURE';

// Action creators
const partnershipProductSummariesRequest = () =>
  createAction(PARTNERSHIP_PRODUCT_SUMMARIES_REQUEST);
const partnershipProductSummariesSuccess = (response: ListProductsResponse) =>
  createAction(PARTNERSHIP_PRODUCT_SUMMARIES_SUCCESS, response);
const partnershipProductSummariesFailure = (err: string) =>
  createAction(PARTNERSHIP_PRODUCT_SUMMARIES_FAILURE, err);

export const fetchPartnershipProductSummaries = (
  apiKey: string,
  contentLanguage: string,
  includePrices = false,
  ignoreBlacklistedLanguages = false
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(partnershipProductSummariesRequest());
  return axios
    .get(`${config.apiUrl}/products`, {
      params: {
        ignore_blacklisted_languages: ignoreBlacklistedLanguages,
        include_prices: includePrices,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(partnershipProductSummariesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(partnershipProductSummariesFailure(err.message));
    });
};

const fetchPartnershipPackageProductSummariesRequest = () =>
  createAction(PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_REQUEST);
const fetchPartnershipPackageProductSummariesSuccess = (response: ListProductsResponse) =>
  createAction(PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_SUCCESS, response);
const fetchPartnershipPackageProductSummariesFailure = (err: string) =>
  createAction(PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_FAILURE, err);

export const fetchPartnershipPackageProductSummaries = (
  apiKey: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchPartnershipPackageProductSummariesRequest());
  return axios
    .get(`${config.apiUrl}/products/partnershippackage`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(fetchPartnershipPackageProductSummariesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPartnershipPackageProductSummariesFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof partnershipProductSummariesRequest>
  | ReturnType<typeof partnershipProductSummariesSuccess>
  | ReturnType<typeof partnershipProductSummariesFailure>
  | ReturnType<typeof fetchPartnershipPackageProductSummariesRequest>
  | ReturnType<typeof fetchPartnershipPackageProductSummariesSuccess>
  | ReturnType<typeof fetchPartnershipPackageProductSummariesFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case PARTNERSHIP_PRODUCT_SUMMARIES_FAILURE:
      return action.payload;
    case PARTNERSHIP_PRODUCT_SUMMARIES_REQUEST:
    case PARTNERSHIP_PRODUCT_SUMMARIES_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: ProductSummary[] = [], action: Action) => {
  switch (action.type) {
    case PARTNERSHIP_PRODUCT_SUMMARIES_SUCCESS:
      return action.payload.products;
    case PARTNERSHIP_PRODUCT_SUMMARIES_REQUEST:
    case PARTNERSHIP_PRODUCT_SUMMARIES_FAILURE:
      return [];
    default:
      return state;
  }
};

const allPackages = (state: ProductSummary[] = [], action: Action) => {
  switch (action.type) {
    case PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_SUCCESS:
      return action.payload.products;
    case PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_REQUEST:
    case PARTNERSHIP_PACKAGE_PRODUCT_SUMMARIES_FAILURE:
      return [];
    default:
      return state;
  }
};

export interface PartnershipProductSummariesState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
  allPackages: ReturnType<typeof allPackages>;
}

export default combineReducers({
  error,
  all,
  allPackages,
});
