import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { MediaDownloadOrder } from 'models/mediaDownloadOrder';
import { createAction } from 'ducks/actionHelpers';

const FETCH_MEDIA_DOWNLOAD_ORDER_REQUEST = 'FETCH_MEDIA_DOWNLOAD_ORDER_REQUEST';
const FETCH_MEDIA_DOWNLOAD_ORDER_SUCCESS = 'FETCH_MEDIA_DOWNLOAD_ORDER_SUCCESS';
const FETCH_MEDIA_DOWNLOAD_ORDER_FAILURE = 'FETCH_MEDIA_DOWNLOAD_ORDER_FAILURE';

const fetchMediaDownloadOrderRequest = () => createAction(FETCH_MEDIA_DOWNLOAD_ORDER_REQUEST);
const fetchMediaDownloadOrderSuccess = (response: MediaDownloadOrder) =>
  createAction(FETCH_MEDIA_DOWNLOAD_ORDER_SUCCESS, response);
const fetchMediaDownloadOrderFailure = (err: string) =>
  createAction(FETCH_MEDIA_DOWNLOAD_ORDER_FAILURE, err);

export const fetchMediaDownloadOrder = (
  apiKey: string,
  orderId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchMediaDownloadOrderRequest());

  return axios
    .get(`${config.apiUrl}/mediadownloadorders/${orderId}`, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchMediaDownloadOrderSuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchMediaDownloadOrderFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchMediaDownloadOrderRequest>
  | ReturnType<typeof fetchMediaDownloadOrderSuccess>
  | ReturnType<typeof fetchMediaDownloadOrderFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_ORDER_FAILURE:
      return action.payload;
    case FETCH_MEDIA_DOWNLOAD_ORDER_REQUEST:
    case FETCH_MEDIA_DOWNLOAD_ORDER_SUCCESS:
      return '';
    default:
      return state;
  }
};

const order = (state: MediaDownloadOrder | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_ORDER_SUCCESS:
      return action.payload;
    case FETCH_MEDIA_DOWNLOAD_ORDER_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface MediaDownloadOrderState {
  error: ReturnType<typeof error>;
  order: ReturnType<typeof order>;
}

export default combineReducers({
  error,
  order,
});
