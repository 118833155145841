import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import config from 'config';
import { escapeProductFieldNames } from 'lib/util/escapeFieldName';
import { Product } from '../../models/product';
import { createAction } from '../actionHelpers';
import { ReduxState } from 'ducks';
import { RE_FETCH_PRODUCT_SUCCESS, reFetchProductSuccess } from 'ducks/client/product';

// Actions
const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
const PRODUCT_FAILURE = 'PRODUCT_FAILURE';
const PRODUCTS_BY_IDS_REQUEST = 'PRODUCTS_BY_IDS_REQUEST';
const PRODUCTS_BY_IDS_SUCCESS = 'PRODUCTS_BY_IDS_SUCCESS';
const PRODUCTS_BY_IDS_FAILURE = 'PRODUCTS_BY_IDS_FAILURE';

// Action creators
const productRequest = () => createAction(PRODUCT_REQUEST);
const productSuccess = (payload: Product) => createAction(PRODUCT_SUCCESS, payload);
const productFailure = (payload: string) => createAction(PRODUCT_FAILURE, payload);

export const fetchProduct = (apiKey: string, id: string, contentLanguage: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(productRequest());

  return axios
    .get(`${config.apiUrl}/products/${id}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(productSuccess(response.data));
    })
    .catch((err) => {
      dispatch(productFailure(err.message));
    });
};

const fetchProductsByIdsRequest = () => createAction(PRODUCTS_BY_IDS_REQUEST);
const fetchProductsByIdsSuccess = (payload: Product[]) =>
  createAction(PRODUCTS_BY_IDS_SUCCESS, payload);
const fetchProductsByIdsFailure = (payload: string) =>
  createAction(PRODUCTS_BY_IDS_FAILURE, payload);

export const fetchProductsByIds = (
  apiKey: string,
  productIds: string[],
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchProductsByIdsRequest());
  const requests = productIds.map((id) =>
    axios.get(`${config.apiUrl}/products/${id}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
  );
  return axios
    .all(requests)
    .then((responses) => {
      dispatch(fetchProductsByIdsSuccess(responses.map((response) => response.data)));
    })
    .catch((err) => {
      dispatch(fetchProductsByIdsFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof productRequest>
  | ReturnType<typeof productSuccess>
  | ReturnType<typeof productFailure>
  | ReturnType<typeof fetchProductsByIdsRequest>
  | ReturnType<typeof fetchProductsByIdsSuccess>
  | ReturnType<typeof fetchProductsByIdsFailure>
  | ReturnType<typeof reFetchProductSuccess>;

// Selectors
export const selectIsProductGuestPaymentTypeEnabled = createSelector(
  (state: ReduxState) =>
    state.server.product.product?.booking_widget_settings?.guest_payment_settings,
  (guestPaymentSettings): boolean => guestPaymentSettings?.is_product_payment_type_enabled ?? false
);
export const selectAcceptedProductGuestPaymentTypes = createSelector(
  (state: ReduxState) =>
    state.server.product.product?.booking_widget_settings?.guest_payment_settings,
  (guestPaymentSettings): Array<'PAY_ON_BOARD' | 'PAID_IN_FULL'> =>
    guestPaymentSettings?.accepted_guest_payment_types ?? []
);

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case PRODUCT_FAILURE:
      return action.payload;
    case PRODUCT_REQUEST:
    case PRODUCT_SUCCESS:
      return '';
    default:
      return state;
  }
};

const product = (state: Product | null = null, action: Action) => {
  switch (action.type) {
    case PRODUCT_SUCCESS:
    case RE_FETCH_PRODUCT_SUCCESS:
      return escapeProductFieldNames(action.payload);
    case PRODUCT_REQUEST:
    case PRODUCT_FAILURE:
      return null;
    default:
      return state;
  }
};

const productsByIds = (state: Product[] = [], action: Action) => {
  switch (action.type) {
    case PRODUCTS_BY_IDS_SUCCESS:
      return action.payload;
    case PRODUCTS_BY_IDS_REQUEST:
    case PRODUCTS_BY_IDS_FAILURE:
      return [];
    default:
      return state;
  }
};

export interface ProductState {
  error: ReturnType<typeof error>;
  product: ReturnType<typeof product>;
  productsByIds: ReturnType<typeof productsByIds>;
}

export default combineReducers({
  error,
  product,
  productsByIds,
});
