import sortBy from 'lodash/sortBy';

import { ProductInstance } from '../../models/productInstance';
import { ReservationLineItem } from '../../models/reservation';

export const sortedLineItems = (
  lineItems: ReservationLineItem[],
  productInstance: ProductInstance
) => {
  const units = productInstance.units;

  // Sort order guarantees that base fare line items appear before other line items.
  const getSortOrder = (lineItem: ReservationLineItem) => {
    if (!lineItem.title) {
      return 0;
    }

    for (let i = 0; i < units.length; i++) {
      const unit = units[i];

      if (lineItem.title === unit.title) {
        return i;
      }

      if (unit.guest_type && lineItem.title === unit.guest_type.title) {
        return i;
      }
    }

    return units.length + 1;
  };

  return sortBy(lineItems, getSortOrder);
};
