// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://c9923a8be0f34966b16bdad6cb9e1cd4@o172235.ingest.sentry.io/5922356',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.5,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  ignoreErrors: [
    // Ignore 'Illegal invocation' because it happens often for GA and does not seem to impact users.
    'Illegal invocation',

    // This is a problem with FB/Insta, not our code.
    // Ref: https://stackoverflow.com/questions/72488297/referenceerror-getvaluesofautofillinputs-cant-find-variable-paymentautofillco
    "Can't find variable: PaymentAutofillConfig",

    // Instagram browser
    "Can't find variable: _AutofillCallbackHandler",
  ],

  environment: process.env.NEXT_PUBLIC_ENV_NAME ?? 'development',
});
