import { ContentLanguage } from 'models/settings';

// When adding a language option, the following imports should also be updated.
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';

export const languageOptionMap: {
  [language in ContentLanguage]: {
    name: string;
    iso: string;
    monthYearFormat: string;
    dateWithDayOfWeekFormat: string;
  };
} = {
  EN_US: {
    name: 'English',
    iso: 'en-US',
    monthYearFormat: 'MMM YYYY',
    dateWithDayOfWeekFormat: 'dddd, D MMMM YYYY',
  },
  JA_JP: {
    name: '日本語',
    iso: 'ja-JP',
    monthYearFormat: 'YYYY年M月',
    dateWithDayOfWeekFormat: 'YYYY年M月D日(ddd)',
  },
  KO_KR: {
    name: '한국어',
    iso: 'ko-KR',
    monthYearFormat: 'YYYY년 M월',
    dateWithDayOfWeekFormat: 'YYYY년 MMMM D일 dddd',
  },
  ZH_CN: {
    name: '简体中文',
    iso: 'zh-CN',
    monthYearFormat: 'YYYY年M月',
    dateWithDayOfWeekFormat: 'YYYY年M月D日(ddd)',
  },
  ZH_TW: {
    name: '繁體中文',
    iso: 'zh-TW',
    monthYearFormat: 'YYYY年M月',
    dateWithDayOfWeekFormat: 'YYYY年M月D日(ddd)',
  },
};

export const getMonthYearFormat = (language: string) => {
  for (const lang of Object.keys(languageOptionMap)) {
    const opt = languageOptionMap[lang as ContentLanguage];
    if (opt.iso === language) {
      return opt.monthYearFormat;
    }
  }

  return 'MMM YYYY';
};

export const getDateWithDayOfWeekFormat = (language: string) => {
  for (const lang of Object.keys(languageOptionMap)) {
    const opt = languageOptionMap[lang as ContentLanguage];
    if (opt.iso === language) {
      return opt.dateWithDayOfWeekFormat;
    }
  }

  return 'LL';
};
