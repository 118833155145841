import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { GroupBookingTemplate } from 'models/groupBookingTemplate';
import { createAction } from 'ducks/actionHelpers';

const FETCH_GROUP_BOOKING_TEMPLATE_REQUEST = 'FETCH_GROUP_BOOKING_TEMPLATE_REQUEST';
const FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS = 'FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS';
const FETCH_GROUP_BOOKING_TEMPLATE_FAILURE = 'FETCH_GROUP_BOOKING_TEMPLATE_FAILURE';

const fetchGroupBookingTemplateRequest = () => createAction(FETCH_GROUP_BOOKING_TEMPLATE_REQUEST);
const fetchGroupBookingTemplateSuccess = (response: GroupBookingTemplate) =>
  createAction(FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS, response);
const fetchGroupBookingTemplateFailure = (err: string) =>
  createAction(FETCH_GROUP_BOOKING_TEMPLATE_FAILURE, err);

export const fetchGroupBookingTemplate = (
  apiKey: string,
  groupId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchGroupBookingTemplateRequest());

  return axios
    .get(`${config.apiUrl}/groupbookingtemplates/${groupId}`, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchGroupBookingTemplateSuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchGroupBookingTemplateFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchGroupBookingTemplateRequest>
  | ReturnType<typeof fetchGroupBookingTemplateSuccess>
  | ReturnType<typeof fetchGroupBookingTemplateFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_GROUP_BOOKING_TEMPLATE_FAILURE:
      return action.payload;
    case FETCH_GROUP_BOOKING_TEMPLATE_REQUEST:
    case FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS:
      return '';
    default:
      return state;
  }
};

const groupBookingTemplate = (state: GroupBookingTemplate | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS:
      return action.payload;
    case FETCH_GROUP_BOOKING_TEMPLATE_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface GroupBookingTemplateState {
  error: ReturnType<typeof error>;
  groupBookingTemplate: ReturnType<typeof groupBookingTemplate>;
}

export default combineReducers({
  error,
  groupBookingTemplate,
});
