import axios from 'axios';
import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import { IssueReservationReceiptRequest, ReservationReceipt } from 'models/reservationReceipt';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import FileSaver from 'file-saver';

// Actions
const ISSUE_RESERVATION_RECEIPT_REQUEST = 'ISSUE_RESERVATION_RECEIPT_REQUEST';
const ISSUE_RESERVATION_RECEIPT_SUCCESS = 'ISSUE_RESERVATION_RECEIPT_SUCCESS';
const ISSUE_RESERVATION_RECEIPT_FAILURE = 'ISSUE_RESERVATION_RECEIPT_FAILURE';
const ISSUE_RESERVATION_RECEIPT_FAILURE_STATUS_CODE =
  'ISSUE_RESERVATION_RECEIPT_FAILURE_STATUS_CODE';
const DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST = 'DOWNLOAD_RESERVATION_RECEIPT_REQUEST';
const DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS = 'DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS';
const DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE = 'DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE';

// Action creators
const issueReservationReceiptRequest = () => createAction(ISSUE_RESERVATION_RECEIPT_REQUEST);
const issueReservationReceiptSuccess = (response: ReservationReceipt) =>
  createAction(ISSUE_RESERVATION_RECEIPT_SUCCESS, response);
const issueReservationReceiptFailure = (err: string) =>
  createAction(ISSUE_RESERVATION_RECEIPT_FAILURE, err);
const issueReservationReceiptFailureStatusCode = (errStatusCode: number) =>
  createAction(ISSUE_RESERVATION_RECEIPT_FAILURE_STATUS_CODE, errStatusCode);

export const issueReservationReceipt = (apiKey: string, req: IssueReservationReceiptRequest) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(issueReservationReceiptRequest());
  return axios
    .post(`${config.apiUrl}/reservationreceipts`, req, {
      headers: { 'x-api-key': apiKey },
    })
    .then((response) => {
      dispatch(issueReservationReceiptSuccess(response.data));
    })
    .catch((err) => {
      dispatch(issueReservationReceiptFailure(err.message));
      dispatch(issueReservationReceiptFailureStatusCode(err.response.status));
    });
};

const downloadReservationReceiptPdfRequest = () =>
  createAction(DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST);
const downloadReservationReceiptPdfSuccess = () =>
  createAction(DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS);
const downloadReservationReceiptPdfFailure = (err: string) =>
  createAction(DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE, err);

export const downloadReservationReceiptPdf = (apiKey: string, reservationId: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(downloadReservationReceiptPdfRequest());
  return axios
    .post(`${config.apiUrl}/reservationreceipts/${reservationId}/pdf`, undefined, {
      headers: { 'x-api-key': apiKey },
      responseType: 'blob',
    })
    .then((response) => {
      const filename =
        response.headers?.['content-disposition'].split('filename=')[1].split(';')[0] ??
        `${reservationId}_receipt.pdf`;
      dispatch(downloadReservationReceiptPdfSuccess());
      FileSaver.saveAs(response.data, filename);
    })
    .catch((err) => {
      dispatch(downloadReservationReceiptPdfFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof issueReservationReceiptRequest>
  | ReturnType<typeof issueReservationReceiptSuccess>
  | ReturnType<typeof issueReservationReceiptFailure>
  | ReturnType<typeof issueReservationReceiptFailureStatusCode>
  | ReturnType<typeof downloadReservationReceiptPdfRequest>
  | ReturnType<typeof downloadReservationReceiptPdfSuccess>
  | ReturnType<typeof downloadReservationReceiptPdfFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case ISSUE_RESERVATION_RECEIPT_FAILURE:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE:
      return action.payload;
    case ISSUE_RESERVATION_RECEIPT_REQUEST:
    case ISSUE_RESERVATION_RECEIPT_SUCCESS:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS:
      return '';
    default:
      return state;
  }
};

const errorStatusCode = (state = 0, action: Action) => {
  switch (action.type) {
    case ISSUE_RESERVATION_RECEIPT_FAILURE_STATUS_CODE:
      return action.payload;
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case ISSUE_RESERVATION_RECEIPT_REQUEST:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST:
      return true;
    case ISSUE_RESERVATION_RECEIPT_SUCCESS:
    case ISSUE_RESERVATION_RECEIPT_FAILURE:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE:
      return false;
    default:
      return state;
  }
};

const all = (state: ReservationReceipt | null = null, action: Action) => {
  switch (action.type) {
    case ISSUE_RESERVATION_RECEIPT_SUCCESS:
      return action.payload;
    case ISSUE_RESERVATION_RECEIPT_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface ReservationReceiptState {
  error: ReturnType<typeof error>;
  errorStatusCode: ReturnType<typeof errorStatusCode>;
  loading: ReturnType<typeof loading>;
  all: ReturnType<typeof all>;
}

export default combineReducers({
  error,
  errorStatusCode,
  loading,
  all,
});
