import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { Promotion } from '../../models/promotion';
import { createAction } from '../actionHelpers';

// Actions
const PROMOTION_REQUEST = 'PROMOTION_REQUEST';
export const PROMOTION_SUCCESS = 'PROMOTION_SUCCESS';
const PROMOTION_FAILURE = 'PROMOTION_FAILURE';

// Action creators
const promotionRequest = () => createAction(PROMOTION_REQUEST);
const promotionSuccess = (payload: Promotion) => createAction(PROMOTION_SUCCESS, payload);
const promotionFailure = (payload: string) => createAction(PROMOTION_FAILURE, payload);

export const fetchPromotion = (apiKey: string, id: string, contentLanguage: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(promotionRequest());
  return axios
    .get(`${config.apiUrl}/promotions/${id}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(promotionSuccess(response.data));
    })
    .catch((err) => {
      dispatch(promotionFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof promotionRequest>
  | ReturnType<typeof promotionSuccess>
  | ReturnType<typeof promotionFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case PROMOTION_FAILURE:
      return action.payload;
    case PROMOTION_REQUEST:
    case PROMOTION_SUCCESS:
      return '';
    default:
      return state;
  }
};

const promotion = (state: Promotion | null = null, action: Action) => {
  switch (action.type) {
    case PROMOTION_SUCCESS:
      return action.payload;
    case PROMOTION_REQUEST:
    case PROMOTION_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface PromotionState {
  error: ReturnType<typeof error>;
  promotion: ReturnType<typeof promotion>;
}

export default combineReducers({
  error,
  promotion,
});
