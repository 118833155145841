import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { escapeProductInstanceFieldNames } from 'lib/util/escapeFieldName';
import {
  ProductInstance,
  ProductInstancesResponse,
  GetMultiProductInstancesResponse,
} from '../../models/productInstance';
import { createAction } from '../actionHelpers';

// Actions
const PRODUCT_INSTANCES_REQUEST = 'PRODUCT_INSTANCES_REQUEST';
const PRODUCT_INSTANCES_SUCCESS = 'PRODUCT_INSTANCES_SUCCESS';
const PRODUCT_INSTANCES_FAILURE = 'PRODUCT_INSTANCES_FAILURE';

const FETCH_MULTI_PRODUCT_INSTANCES_REQUEST = 'FETCH_MULTI_PRODUCT_INSTANCES_REQUEST';
const FETCH_MULTI_PRODUCT_INSTANCES_SUCCESS = 'FETCH_MULTI_PRODUCT_INSTANCES_SUCCESS';
const FETCH_MULTI_PRODUCT_INSTANCES_FAILURE = 'FETCH_MULTI_PRODUCT_INSTANCES_FAILURE';

// Action creators
const productInstancesRequest = () => createAction(PRODUCT_INSTANCES_REQUEST);
const productInstancesSuccess = (response: ProductInstancesResponse) =>
  createAction(PRODUCT_INSTANCES_SUCCESS, response);
const productInstancesFailure = (err: string) => createAction(PRODUCT_INSTANCES_FAILURE, err);

const fetchMultiProductInstancesRequest = () => createAction(FETCH_MULTI_PRODUCT_INSTANCES_REQUEST);
const fetchMultiProductInstancesSuccess = (response: GetMultiProductInstancesResponse) =>
  createAction(FETCH_MULTI_PRODUCT_INSTANCES_SUCCESS, response);
const fetchMultiProductInstancesFailure = (err: string) =>
  createAction(FETCH_MULTI_PRODUCT_INSTANCES_FAILURE, err);

let fetchProductInstancesCancel: (message?: string) => void;
export const fetchProductInstances = (
  apiKey: string,
  productId: string,
  startDate: string,
  endDate: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): void => {
  if (fetchProductInstancesCancel) {
    fetchProductInstancesCancel();
  }
  dispatch(productInstancesRequest());
  axios
    .get(`${config.apiUrl}/products/${productId}/instances`, {
      params: {
        start_date_local_from: startDate,
        start_date_local_to: endDate,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      cancelToken: new axios.CancelToken(function executor(c) {
        fetchProductInstancesCancel = c;
      }),
    })
    .then((response) => {
      dispatch(productInstancesSuccess(response.data));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(productInstancesFailure('canceled'));
      } else {
        dispatch(productInstancesFailure(err.message));
      }
    });
};

let fetchMultiProductInstancesCancel: (message?: string) => void;
export const fetchMultiProductInstances = (
  apiKey: string,
  productIds: string[],
  startDate: string,
  endDate: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): void => {
  if (fetchMultiProductInstancesCancel) {
    fetchMultiProductInstancesCancel();
  }
  dispatch(fetchMultiProductInstancesRequest());
  axios
    .get(`${config.apiUrl}/products/instances`, {
      params: {
        product_ids: productIds,
        start_date_local_from: startDate,
        start_date_local_to: endDate,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      cancelToken: new axios.CancelToken(function executor(c) {
        fetchMultiProductInstancesCancel = c;
      }),
    })
    .then((response) => {
      dispatch(fetchMultiProductInstancesSuccess(response.data));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(fetchMultiProductInstancesFailure('canceled'));
      } else {
        dispatch(fetchMultiProductInstancesFailure(err.message));
      }
    });
};

type Action =
  | ReturnType<typeof productInstancesRequest>
  | ReturnType<typeof productInstancesSuccess>
  | ReturnType<typeof productInstancesFailure>
  | ReturnType<typeof fetchMultiProductInstancesRequest>
  | ReturnType<typeof fetchMultiProductInstancesSuccess>
  | ReturnType<typeof fetchMultiProductInstancesFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case PRODUCT_INSTANCES_FAILURE:
    case FETCH_MULTI_PRODUCT_INSTANCES_FAILURE:
      return action.payload;
    case PRODUCT_INSTANCES_REQUEST:
    case PRODUCT_INSTANCES_SUCCESS:
    case FETCH_MULTI_PRODUCT_INSTANCES_REQUEST:
    case FETCH_MULTI_PRODUCT_INSTANCES_SUCCESS:
      return '';
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case PRODUCT_INSTANCES_REQUEST:
    case FETCH_MULTI_PRODUCT_INSTANCES_REQUEST:
      return true;
    case PRODUCT_INSTANCES_SUCCESS:
    case PRODUCT_INSTANCES_FAILURE:
    case FETCH_MULTI_PRODUCT_INSTANCES_SUCCESS:
    case FETCH_MULTI_PRODUCT_INSTANCES_FAILURE:
      return false;
    default:
      return state;
  }
};

const all = (state: ProductInstance[] = [], action: Action) => {
  switch (action.type) {
    case PRODUCT_INSTANCES_SUCCESS:
      return action.payload.instances?.map((instance) => escapeProductInstanceFieldNames(instance));
    case PRODUCT_INSTANCES_REQUEST:
    case PRODUCT_INSTANCES_FAILURE:
      return [];
    default:
      return state;
  }
};

const productGroup = (
  state: GetMultiProductInstancesResponse['product_instances_by_products'] = [],
  action: Action
) => {
  switch (action.type) {
    case FETCH_MULTI_PRODUCT_INSTANCES_SUCCESS:
      return action.payload.product_instances_by_products?.map((instances) => {
        return {
          product_id: instances.product_id,
          product_instances: instances.product_instances?.map((instance) =>
            escapeProductInstanceFieldNames(instance)
          ),
        };
      }) as GetMultiProductInstancesResponse['product_instances_by_products'];
    case FETCH_MULTI_PRODUCT_INSTANCES_REQUEST:
    case FETCH_MULTI_PRODUCT_INSTANCES_FAILURE:
      return [];
    default:
      return state;
  }
};

export interface ProductInstancesState {
  error: ReturnType<typeof error>;
  loading: ReturnType<typeof loading>;
  all: ReturnType<typeof all>;
  productGroup: ReturnType<typeof productGroup>;
}

export default combineReducers({
  error,
  loading,
  all,
  productGroup,
});
