import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import lodashUniq from 'lodash/uniq';

import { ReduxState } from 'ducks';
import { createAction } from 'ducks/actionHelpers';

// Actions

const MARK_PRODUCT_VISITED = 'MARK_PRODUCT_VISITED';

const markProductVisitedAction = (productId: string) =>
  createAction(MARK_PRODUCT_VISITED, productId);

const visitedProductsKey = 'product-visited';

export const markProductVisited = (
  productId: string
): ThunkAction<void, ReduxState, Record<string, unknown>, AnyAction> => (
  dispatch,
  getState
): void => {
  dispatch(markProductVisitedAction(productId));

  const visitedProductIds = getState().visitedProductIds.join(',');

  // Store in local storage
  localStorage.setItem(visitedProductsKey, visitedProductIds);

  // Store in cookie
  document.cookie = `${visitedProductsKey}=${visitedProductIds}`;
};

export const loadVisitedProductIds = (): string[] => {
  const lsVisitedProducts = localStorage.getItem(visitedProductsKey)?.split(',') || [];
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${visitedProductsKey}=`));
  const cookieVisitedProducts = cookie?.split('=')[1]?.split(',') || [];

  return lodashUniq([...lsVisitedProducts, ...cookieVisitedProducts]);
};

type Action = ReturnType<typeof markProductVisitedAction>;

// Selector

export const selectVisitedProductIds = (state: ReduxState) => state.visitedProductIds;

const visitedProductIds = (state: string[] = [], action: Action) => {
  switch (action.type) {
    case MARK_PRODUCT_VISITED:
      // order: most recent first
      return [action.payload, ...state.filter((id) => id !== action.payload)];
    default:
      return state;
  }
};

export type VisitedProductsState = string[];

export default visitedProductIds;
