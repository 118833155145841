import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import { getAmountFromMoneyString, getCurrencyCodeFromMoneyString } from 'lib/util/util';
import {
  unescapeCalculateReservationFaresRequest,
  unescapeCalculateUpdateReservationFaresRequest,
} from 'lib/util/escapeFieldName';
import { ReduxState } from 'ducks';
import config from 'config';
import { sortedLineItems } from 'lib/util/sortedLineItems';
import {
  CalculateNewReservationFaresRequest,
  CalculateReservationFaresRequest,
  CalculateReservationFaresResponse,
  CalculateUpdateReservationFaresRequest,
  CalculateUpdateReservationFaresResponse,
} from 'models/reservation';
import { createAction } from 'ducks/actionHelpers';
import { ProductInstance } from 'models/productInstance';

// Actions
const GET_EXISTING_RESERVATION_FARES_REQUEST = 'GET_EXISTING_RESERVATION_FARES_REQUEST';
const GET_EXISTING_RESERVATION_FARES_SUCCESS = 'GET_EXISTING_RESERVATION_FARES_SUCCESS';
const GET_EXISTING_RESERVATION_FARES_FAILURE = 'GET_EXISTING_RESERVATION_FARES_FAILURE';
const GET_FARES_REQUEST = 'GET_FARES_REQUEST';
const GET_FARES_SUCCESS = 'GET_FARES_SUCCESS';
const GET_FARES_FAILURE = 'GET_FARES_FAILURE';
const GET_UPDATE_RESERVATION_FARES_REQUEST = 'GET_UPDATE_RESERVATION_FARES_REQUEST';
const GET_UPDATE_RESERVATION_FARES_SUCCESS = 'GET_UPDATE_RESERVATION_FARES_SUCCESS';
const GET_UPDATE_RESERVATION_FARES_FAILURE = 'GET_UPDATE_RESERVATION_FARES_FAILURE';

// Action creators
const getFaresRequest = (payload: CalculateNewReservationFaresRequest) =>
  createAction(GET_FARES_REQUEST, payload);
const getFaresSuccess = (payload: CalculateReservationFaresResponse) =>
  createAction(GET_FARES_SUCCESS, payload);
const getFaresFailure = (payload: string) => createAction(GET_FARES_FAILURE, payload);

let calculateReservationFaresCancel: (() => void) | null = null;
export const calculateReservationFares = (
  apiKey: string,
  req: CalculateNewReservationFaresRequest,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): void => {
  calculateReservationFaresCancel?.();
  req = unescapeCalculateReservationFaresRequest(req);

  dispatch(getFaresRequest(req));
  axios
    .post(`${config.apiUrl}/reservations/calculatefares`, req, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      cancelToken: new axios.CancelToken(function executor(c) {
        calculateReservationFaresCancel = c;
      }),
    })
    .then((response) => {
      dispatch(getFaresSuccess(response.data));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(getFaresFailure('canceled'));
      } else {
        dispatch(getFaresFailure(err.message));
      }
    });
};

const getExistingReservationFaresRequest = (payload: CalculateReservationFaresRequest) =>
  createAction(GET_EXISTING_RESERVATION_FARES_REQUEST, payload);
const getExistingReservationFaresSuccess = (payload: CalculateReservationFaresResponse) =>
  createAction(GET_EXISTING_RESERVATION_FARES_SUCCESS, payload);
const getExistingReservationFaresFailure = (payload: string) =>
  createAction(GET_EXISTING_RESERVATION_FARES_FAILURE, payload);

let calculateExistingReservationFaresCancel: (() => void) | null = null;
export const calculateExistingReservationFares = (
  apiKey: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): void => {
  calculateExistingReservationFaresCancel?.();

  const req: CalculateReservationFaresRequest = {
    reservation_id: reservationId,

    // Product instance ID and guests are required params in the API. Pass dummy values.
    product_instance_id: '12345',
    guests: [
      {
        guest_type_key: 'XXX',
        field_responses: [],
      },
    ],
  };
  dispatch(getExistingReservationFaresRequest(req));
  axios
    .post(`${config.apiUrl}/reservations/calculatefares`, req, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      cancelToken: new axios.CancelToken(function executor(c) {
        calculateExistingReservationFaresCancel = c;
      }),
    })
    .then((response) => {
      dispatch(getExistingReservationFaresSuccess(response.data));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(getExistingReservationFaresFailure('canceled'));
      } else {
        dispatch(getExistingReservationFaresFailure(err.message));
      }
    });
};

const getUpdateReservationFaresRequest = (payload: CalculateUpdateReservationFaresRequest) =>
  createAction(GET_UPDATE_RESERVATION_FARES_REQUEST, payload);
const getUpdateReservationFaresSuccess = (payload: CalculateUpdateReservationFaresResponse) =>
  createAction(GET_UPDATE_RESERVATION_FARES_SUCCESS, payload);
const getUpdateReservationFaresFailure = (payload: string) =>
  createAction(GET_UPDATE_RESERVATION_FARES_FAILURE, payload);

export const calculateUpdateReservationFares = (
  apiKey: string,
  id: string,
  req: CalculateUpdateReservationFaresRequest,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): void => {
  req = unescapeCalculateUpdateReservationFaresRequest(req);

  dispatch(getUpdateReservationFaresRequest(req));
  axios
    .post(`${config.apiUrl}/reservations/${id}/calculateupdatefares`, req, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(getUpdateReservationFaresSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getUpdateReservationFaresFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof getFaresRequest>
  | ReturnType<typeof getFaresSuccess>
  | ReturnType<typeof getFaresFailure>
  | ReturnType<typeof getExistingReservationFaresRequest>
  | ReturnType<typeof getExistingReservationFaresSuccess>
  | ReturnType<typeof getExistingReservationFaresFailure>
  | ReturnType<typeof getUpdateReservationFaresRequest>
  | ReturnType<typeof getUpdateReservationFaresSuccess>
  | ReturnType<typeof getUpdateReservationFaresFailure>;

// Selectors
export const selectSettlementAmount = createSelector(
  (state: ReduxState) => state.fares.fares,
  (currentFares) => (currentFares && currentFares.settlement_currency_amount_gross) || ''
);

export const selectReservationLineItems = createSelector(
  (state: ReduxState) => state.fares.fares,
  (state: ReduxState, props: { productInstance?: ProductInstance }) => props.productInstance,
  (currentFares, productInstance) => {
    const lineItems = (currentFares && currentFares.line_items) || [];
    if (lineItems && productInstance) {
      return sortedLineItems(lineItems, productInstance);
    }

    return lineItems;
  }
);

export const selectReservationMarkupAmount = createSelector(
  (state: ReduxState) => state.fares.fares,
  (currentFares) => (currentFares && currentFares.variable_markup_amount) || ''
);

export const selectReservationAmountTotal = createSelector(
  (state: ReduxState) => state.fares.fares,
  (currentFares) => (currentFares && currentFares.amount_gross) || ''
);

export const selectReservationPaidAmount = createSelector(
  (state: ReduxState) => state.fares.fares,
  (currentFares) => (currentFares && currentFares.amount_paid) || ''
);

export const selectReservationAmountCurrencyCode = createSelector(
  selectReservationAmountTotal,
  (amountTotal) => getCurrencyCodeFromMoneyString(amountTotal)
);

export const selectReservationAmountValue = createSelector(
  selectReservationAmountTotal,
  (amountTotal) => getAmountFromMoneyString(amountTotal)
);

export const selectReservationHasDiscount = createSelector(
  (state: ReduxState) => state.fares.fares,
  (currentFares) =>
    currentFares &&
    (currentFares.line_items || []).some(
      (lineItem) => (lineItem.amount_gross || '').indexOf('-') !== -1
    )
);

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case GET_FARES_FAILURE:
    case GET_UPDATE_RESERVATION_FARES_FAILURE:
    case GET_EXISTING_RESERVATION_FARES_FAILURE:
      return action.payload;
    case GET_FARES_REQUEST:
    case GET_FARES_SUCCESS:
    case GET_UPDATE_RESERVATION_FARES_REQUEST:
    case GET_UPDATE_RESERVATION_FARES_SUCCESS:
    case GET_EXISTING_RESERVATION_FARES_REQUEST:
    case GET_EXISTING_RESERVATION_FARES_SUCCESS:
      return '';
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case GET_FARES_REQUEST:
    case GET_UPDATE_RESERVATION_FARES_REQUEST:
    case GET_EXISTING_RESERVATION_FARES_REQUEST:
      return true;
    case GET_FARES_SUCCESS:
    case GET_FARES_FAILURE:
    case GET_UPDATE_RESERVATION_FARES_SUCCESS:
    case GET_UPDATE_RESERVATION_FARES_FAILURE:
    case GET_EXISTING_RESERVATION_FARES_SUCCESS:
    case GET_EXISTING_RESERVATION_FARES_FAILURE:
      return false;
    default:
      return state;
  }
};

const existingReservationFares = (
  state: CalculateReservationFaresResponse | null = null,
  action: Action
) => {
  switch (action.type) {
    case GET_EXISTING_RESERVATION_FARES_SUCCESS:
      return action.payload;
    case GET_EXISTING_RESERVATION_FARES_FAILURE:
      return null;
    default:
      return state;
  }
};

const fares = (state: CalculateReservationFaresResponse | null = null, action: Action) => {
  switch (action.type) {
    case GET_FARES_SUCCESS:
    case GET_UPDATE_RESERVATION_FARES_SUCCESS:
      return action.payload;
    case GET_FARES_FAILURE:
    case GET_UPDATE_RESERVATION_FARES_FAILURE:
      return null;
    default:
      return state;
  }
};

const submittedPromoCode = (state = '', action: Action) => {
  switch (action.type) {
    case GET_FARES_REQUEST:
      return action.payload.promo_code || '';
    default:
      return state;
  }
};

export interface FaresState {
  error: ReturnType<typeof error>;
  loading: ReturnType<typeof loading>;
  fares: ReturnType<typeof fares>;
  existingReservationFares: ReturnType<typeof existingReservationFares>;
  submittedPromoCode: ReturnType<typeof submittedPromoCode>;
}

export default combineReducers({
  error,
  loading,
  fares,
  existingReservationFares,
  submittedPromoCode,
});
