import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { escapeProductInstanceFieldNames } from 'lib/util/escapeFieldName';
import { ProductInstance } from 'models/productInstance';
import { createAction } from 'ducks/actionHelpers';
import {
  RE_FETCH_PRODUCT_INSTANCE_SUCCESS,
  reFetchProductInstanceSuccess,
} from 'ducks/client/productInstance';

// Actions

const FETCH_PRODUCT_INSTANCE_REQUEST = 'FETCH_PRODUCT_INSTANCE_REQUEST';
const FETCH_PRODUCT_INSTANCE_SUCCESS = 'FETCH_PRODUCT_INSTANCE_SUCCESS';
const FETCH_PRODUCT_INSTANCE_FAILURE = 'FETCH_PRODUCT_INSTANCE_FAILURE';

// Action creators
const fetchProductInstanceRequest = () => createAction(FETCH_PRODUCT_INSTANCE_REQUEST);
const fetchProductInstanceSuccess = (response: ProductInstance) =>
  createAction(FETCH_PRODUCT_INSTANCE_SUCCESS, response);
const fetchProductInstanceFailure = (err: string) =>
  createAction(FETCH_PRODUCT_INSTANCE_FAILURE, err);

export const fetchProductInstance = (
  apiKey: string,
  id: string,
  contentLanguage: string
): ThunkAction<Promise<any>, Promise<void>, Record<string, unknown>, AnyAction> => async (
  dispatch: ThunkDispatch<Promise<void>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchProductInstanceRequest());
  return axios
    .get(`${config.apiUrl}/products/-/instances/${id}?include_resource_availability=true`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(fetchProductInstanceSuccess(response.data));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(fetchProductInstanceFailure('canceled'));
      } else {
        dispatch(fetchProductInstanceFailure(err.message));
      }
    });
};

type Action =
  | ReturnType<typeof fetchProductInstanceRequest>
  | ReturnType<typeof fetchProductInstanceSuccess>
  | ReturnType<typeof fetchProductInstanceFailure>
  | ReturnType<typeof reFetchProductInstanceSuccess>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_PRODUCT_INSTANCE_FAILURE:
      return action.payload;
    case FETCH_PRODUCT_INSTANCE_REQUEST:
    case FETCH_PRODUCT_INSTANCE_SUCCESS:
      return '';
    default:
      return state;
  }
};

const instance = (state: ProductInstance | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_PRODUCT_INSTANCE_SUCCESS:
    case RE_FETCH_PRODUCT_INSTANCE_SUCCESS:
      return escapeProductInstanceFieldNames(action.payload);
    default:
      return state;
  }
};

export interface ProductInstanceState {
  error: ReturnType<typeof error>;
  instance: ReturnType<typeof instance>;
}

export default combineReducers({
  error,
  instance,
});
