import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { addGuidancePageItemIndices, GuidancePage } from 'models/guidancePage';
import { createAction } from 'ducks/actionHelpers';

const FETCH_DIGITAL_GUIDANCE_PAGE_REQUEST = 'FETCH_DIGITAL_GUIDANCE_PAGE_REQUEST';
const FETCH_DIGITAL_GUIDANCE_PAGE_SUCCESS = 'FETCH_DIGITAL_GUIDANCE_PAGE_SUCCESS';
const FETCH_DIGITAL_GUIDANCE_PAGE_FAILURE = 'FETCH_DIGITAL_GUIDANCE_PAGE_FAILURE';

const fetchDigitalGuidancePageRequest = () => createAction(FETCH_DIGITAL_GUIDANCE_PAGE_REQUEST);
const fetchDigitalGuidancePageSuccess = (response: GuidancePage) =>
  createAction(FETCH_DIGITAL_GUIDANCE_PAGE_SUCCESS, response);
const fetchDigitalGuidancePageFailure = (err: string) =>
  createAction(FETCH_DIGITAL_GUIDANCE_PAGE_FAILURE, err);

export const fetchDigitalGuidancePage = (
  apiKey: string,
  pageId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchDigitalGuidancePageRequest());

  const path = `${config.apiUrl}/guidancepages/${pageId}`;

  return axios
    .get(path, {
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchDigitalGuidancePageSuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchDigitalGuidancePageFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchDigitalGuidancePageRequest>
  | ReturnType<typeof fetchDigitalGuidancePageSuccess>
  | ReturnType<typeof fetchDigitalGuidancePageFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_DIGITAL_GUIDANCE_PAGE_FAILURE:
      return action.payload;
    case FETCH_DIGITAL_GUIDANCE_PAGE_REQUEST:
    case FETCH_DIGITAL_GUIDANCE_PAGE_SUCCESS:
      return '';
    default:
      return state;
  }
};

const page = (state: GuidancePage | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_DIGITAL_GUIDANCE_PAGE_SUCCESS:
      return addGuidancePageItemIndices(action.payload);
    case FETCH_DIGITAL_GUIDANCE_PAGE_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface DigitalGuidancePageState {
  error: ReturnType<typeof error>;
  page: ReturnType<typeof page>;
}

export default combineReducers({
  error,
  page,
});
