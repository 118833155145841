import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { SalesOffer } from 'models/salesOffer';
import { createAction } from 'ducks/actionHelpers';
import { ReduxState } from 'ducks';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export type Trigger =
  | {
      triggerType:
        | 'ETICKET_BEFORE_REDEMPTION'
        | 'ETICKET_AFTER_REDEMPTION'
        | 'RESERVATION_THANK_YOU_PAGE';
    }
  | {
      triggerType: 'ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION';
      currentPosition: google.maps.LatLng;
    }
  | {
      triggerType: 'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION';
      couponId: string;
    };

const getTriggeredSalesOffers = (salesOffers: SalesOffer[] | null, triggers: Trigger[]) => {
  return triggers
    .map((trigger) => {
      switch (trigger.triggerType) {
        case 'ETICKET_BEFORE_REDEMPTION':
        case 'ETICKET_AFTER_REDEMPTION':
        case 'RESERVATION_THANK_YOU_PAGE':
          return (
            salesOffers?.filter(
              (salesOffer) => salesOffer.trigger?.display_trigger_type === trigger.triggerType
            ) ?? []
          );
        case 'ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION':
          return (
            salesOffers?.filter((salesOffer) =>
              salesOffer.trigger?.display_trigger_type === trigger.triggerType &&
              salesOffer.trigger?.location_trigger_settings?.activation_range
                ? google.maps.geometry.spherical.computeDistanceBetween(
                    trigger.currentPosition,
                    new google.maps.LatLng(
                      salesOffer.trigger?.location_trigger_settings?.latitude,
                      salesOffer.trigger?.location_trigger_settings?.longitude
                    )
                  ) <= salesOffer.trigger?.location_trigger_settings?.activation_range
                : false
            ) ?? []
          );
        case 'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION':
          return (
            salesOffers?.filter(
              (salesOffer) =>
                salesOffer.trigger?.display_trigger_type === trigger.triggerType &&
                salesOffer.trigger?.trigger_coupon_ids?.includes(trigger.couponId)
            ) ?? []
          );
        default:
          return [];
      }
    })
    .flat();
};

export const selectApplicableWebsiteSalesOffers = createDeepEqualSelector(
  (state: ReduxState) =>
    state.salesOffers.all?.filter(
      (salesOffer) => !salesOffer.medium || salesOffer.medium === 'WEBSITE'
    ) || [],
  (state: ReduxState) => state.salesOffers.triggers,
  getTriggeredSalesOffers
);

export const selectApplicableEmailSalesOffers = createDeepEqualSelector(
  (state: ReduxState) =>
    state.salesOffers.all?.filter((salesOffer) => salesOffer.medium === 'EMAIL') || [],
  (state: ReduxState) => state.salesOffers.triggers,
  getTriggeredSalesOffers
);

// Actions
const SALES_OFFERS_REQUEST = 'SALES_OFFERS_REQUEST';
export const SALES_OFFERS_SUCCESS = 'SALES_OFFERS_SUCCESS';
const SALES_OFFERS_FAILURE = 'SALES_OFFERS_FAILURE';
const TRIGGER_SALES_OFFER = 'TRIGGER_SALES_OFFER';
const TRIGGER_EMAIL_SALES_OFFER_REQUEST = 'TRIGGER_EMAIL_SALES_OFFER_REQUEST';
const TRIGGER_EMAIL_SALES_OFFER_SUCCESS = 'TRIGGER_EMAIL_SALES_OFFER_SUCCESS';
const TRIGGER_EMAIL_SALES_OFFER_FAILURE = 'TRIGGER_EMAIL_SALES_OFFER_FAILURE';

// Action creators
const salesOffersRequest = () => createAction(SALES_OFFERS_REQUEST);
const salesOffersSuccess = (payload: SalesOffer[]) => createAction(SALES_OFFERS_SUCCESS, payload);
const salesOffersFailure = (payload: string) => createAction(SALES_OFFERS_FAILURE, payload);

export const fetchSalesOffers = (
  apiKey: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(salesOffersRequest());
  return axios
    .get(`${config.apiUrl}/reservations/${reservationId}/salesoffers`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(salesOffersSuccess(response.data.sales_offers));
    })
    .catch((err) => {
      dispatch(salesOffersFailure(err.message));
    });
};

const triggerEmailSalesOfferRequest = () => createAction(TRIGGER_EMAIL_SALES_OFFER_REQUEST);
const triggerEmailSalesOfferSuccess = (salesOfferId: string) =>
  createAction(TRIGGER_EMAIL_SALES_OFFER_SUCCESS, salesOfferId);
const triggerEmailSalesOfferFailure = (payload: string) =>
  createAction(TRIGGER_EMAIL_SALES_OFFER_FAILURE, payload);

export const triggerServerSideSalesOffer = (
  apiKey: string,
  reservationId: string,
  salesOfferId: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(triggerEmailSalesOfferRequest());
  return axios
    .post(
      `${config.apiUrl}/salesoffers/${salesOfferId}/trigger`,
      {
        reservation_id: reservationId,
      },
      {
        headers: { 'x-api-key': apiKey },
      }
    )
    .then(() => {
      dispatch(triggerEmailSalesOfferSuccess(salesOfferId));
    })
    .catch((err) => {
      dispatch(triggerEmailSalesOfferFailure(err.message));
    });
};

export const triggerSalesOffer = (payload: Trigger) => createAction(TRIGGER_SALES_OFFER, payload);

type Action =
  | ReturnType<typeof salesOffersRequest>
  | ReturnType<typeof salesOffersSuccess>
  | ReturnType<typeof salesOffersFailure>
  | ReturnType<typeof triggerSalesOffer>
  | ReturnType<typeof triggerEmailSalesOfferRequest>
  | ReturnType<typeof triggerEmailSalesOfferSuccess>
  | ReturnType<typeof triggerEmailSalesOfferFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case SALES_OFFERS_FAILURE:
      return action.payload;
    case SALES_OFFERS_REQUEST:
    case SALES_OFFERS_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: SalesOffer[] | null = null, action: Action) => {
  switch (action.type) {
    case SALES_OFFERS_SUCCESS:
      return action.payload;
    case SALES_OFFERS_REQUEST:
    case SALES_OFFERS_FAILURE:
      return null;
    case TRIGGER_SALES_OFFER:
      if (action.payload.triggerType === 'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION') {
        const couponId = action.payload.couponId;
        return state?.filter((salesOffer) => salesOffer.content?.coupon_id !== couponId) ?? null;
      }
    case TRIGGER_EMAIL_SALES_OFFER_SUCCESS:
      const salesOfferId = action.payload;
      return state?.filter((salesOffer) => salesOffer.id !== salesOfferId) ?? null;
    default:
      return state;
  }
};

const triggers = (state: Trigger[] = [], action: Action) => {
  switch (action.type) {
    case TRIGGER_SALES_OFFER:
      return [
        action.payload,
        ...state.filter((trigger) => trigger.triggerType !== action.payload.triggerType),
      ];
    default:
      return state;
  }
};

export interface SalesOffersState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
  triggers: ReturnType<typeof triggers>;
}

export default combineReducers({
  error,
  all,
  triggers,
});
