import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import { ReduxState } from 'ducks';
import { createAction } from 'ducks/actionHelpers';

// Actions

const HIDE_POPUP = 'HIDE_POPUP';

const hidePopupAction = (key: string) => createAction(HIDE_POPUP, key);

const popupMessagesToHideKey = 'hide-popup-messages';

export const hidePopup = (
  key: string
): ThunkAction<void, ReduxState, Record<string, unknown>, AnyAction> => (
  dispatch,
  getState
): void => {
  dispatch(hidePopupAction(key));

  const hiddenPopupMessages = getState().hiddenPopupMessages;

  // Store in local storage
  localStorage.setItem(popupMessagesToHideKey, hiddenPopupMessages.join(','));

  // Store in cookie
  document.cookie = `${popupMessagesToHideKey}=${hiddenPopupMessages}`;
};

export const loadHiddenPopupMessages = (): string[] => {
  const lsPopupMessages = localStorage.getItem(popupMessagesToHideKey)?.split(',') || [];
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${popupMessagesToHideKey}=`));
  const cookiePopupMessages = cookie?.split('=')[1]?.split(',') || [];

  return [...new Set([...lsPopupMessages, ...cookiePopupMessages])];
};

type Action = ReturnType<typeof hidePopupAction>;

// Selector

export const selectHiddenPopups = (state: ReduxState) => state.hiddenPopupMessages;

const hiddenPopupMessages = (state: string[] = [], action: Action) => {
  switch (action.type) {
    case HIDE_POPUP:
      return state.includes(action.payload) ? state : [...state, action.payload];
    default:
      return state;
  }
};

export type HiddenPopupMessagesState = string[];

export default hiddenPopupMessages;
