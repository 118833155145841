import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { MediaDownloadSalesPage } from 'models/mediaDownloadSalesPage';
import { createAction } from 'ducks/actionHelpers';

const FETCH_SALES_PAGE_REQUEST = 'FETCH_SALES_PAGE_REQUEST';
const FETCH_SALES_PAGE_SUCCESS = 'FETCH_SALES_PAGE_SUCCESS';
const FETCH_SALES_PAGE_FAILURE = 'FETCH_SALES_PAGE_FAILURE';

const fetchMediaDownloadSalesPageRequest = () => createAction(FETCH_SALES_PAGE_REQUEST);
const fetchMediaDownloadSalesPageSuccess = (response: MediaDownloadSalesPage) =>
  createAction(FETCH_SALES_PAGE_SUCCESS, response);
const fetchMediaDownloadSalesPageFailure = (err: string) =>
  createAction(FETCH_SALES_PAGE_FAILURE, err);

export const fetchMediaDownloadSalesPageForReservation = (
  apiKey: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchMediaDownloadSalesPageRequest());

  return axios
    .get(`${config.apiUrl}/mediadownloadsalespages`, {
      params: {
        reservation_id: reservationId,
        statuses: 'MEDIA_SALES_PAGE_PUBLISHED',
      },
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const filteredPages = (response.data?.media_download_sales_pages ?? []).filter(
        (p: MediaDownloadSalesPage) => !p.is_expired
      );
      const payload = filteredPages.length > 0 ? filteredPages[0] : null;
      dispatch(fetchMediaDownloadSalesPageSuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchMediaDownloadSalesPageFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchMediaDownloadSalesPageRequest>
  | ReturnType<typeof fetchMediaDownloadSalesPageSuccess>
  | ReturnType<typeof fetchMediaDownloadSalesPageFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_SALES_PAGE_FAILURE:
      return action.payload;
    case FETCH_SALES_PAGE_REQUEST:
    case FETCH_SALES_PAGE_SUCCESS:
      return '';
    default:
      return state;
  }
};

const salesPage = (state: MediaDownloadSalesPage | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_SALES_PAGE_SUCCESS:
      return action.payload;
    case FETCH_SALES_PAGE_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface MediaDownloadSalesPageState {
  error: ReturnType<typeof error>;
  salesPage: ReturnType<typeof salesPage>;
}

export default combineReducers({
  error,
  salesPage,
});
